// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import { Field, Form } from 'react-final-form';
import { alpha } from '@material-ui/core';

import { withTranslation } from '../../../../config/i18n';

import MewoInput from '../../mewoInput';

import * as pth from '../../../../helpers/proptypes';

import { composeValidators, required, mustBeEmail } from '../../../../validators';

import { Wrapper, Container, StyledForm, StyledButton, StyledInput } from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function Newsletter({
  colors,
  isLogged,
  modifyUserInfo,
  spacings,
  subscribeToNewsletter,
  t,
  user,
}) {
  if (isLogged && user.withNewsletter) return null;

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
            input: {
              background: colors.text && alpha(colors.text, 0.1),
              text: colors.text,
              helpers: {
                background: colors.text,
                text: colors.background,
              },
            },
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container>
          {isLogged && !user.withNewsletter && (
            <StyledButton onClick={() => modifyUserInfo({ withNewsletter: true })}>
              {t('pages:custom_page.modules.newsletter.subscribe_btn')}
            </StyledButton>
          )}

          {!isLogged && (
            <Form onSubmit={subscribeToNewsletter}>
              {({ handleSubmit, submitting }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <Field
                    name="email"
                    component={MewoInput}
                    use={StyledInput}
                    type="text"
                    placeholder={t('pages:custom_page.modules.newsletter.input_placeholder')}
                    validate={composeValidators(required, mustBeEmail)}
                  />
                  <StyledButton disabled={submitting} type="submit">
                    {t('pages:custom_page.modules.newsletter.subscribe_btn')}
                  </StyledButton>
                </StyledForm>
              )}
            </Form>
          )}
        </Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

Newsletter.propTypes = {
  colors: pth.colorsModule.isRequired,
  isLogged: PropTypes.bool.isRequired,
  modifyUserInfo: PropTypes.func.isRequired,
  spacings: pth.spacings.isRequired,
  subscribeToNewsletter: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  user: pth.user,
};

Newsletter.defaultProps = {
  user: null,
};

export default withTranslation(['common', 'pages'])(Newsletter);

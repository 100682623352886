// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import ReactVisibilitySensor from 'react-visibility-sensor';

import { withTranslation } from '../../../../../config/i18n';

import * as pth from '../../../../../helpers/proptypes';

import { Search, Wrapper, SearchBarPlaceholder } from './styles';

// =============================
// Component
// =============================

class SearchBar extends Component {
  static propTypes = {
    config: pth.config.isRequired,
    value: PropTypes.string.isRequired,
    isPreSearchOpen: PropTypes.bool.isRequired,
    /** Maia audio signal status */
    isSendingMaiaAudioSignal: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    moduleColors: pth.colorsModule.isRequired,
    moduleData: PropTypes.shape({
      id: PropTypes.string,
      headerLogo: PropTypes.oneOf(['logo', 'logo_variant', 'logo_modal']),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    requestMaiaAudioSearch: PropTypes.func.isRequired,
    searchInDescription: PropTypes.bool.isRequired,
    setFullSizeCoverModuleData: PropTypes.func.isRequired,
    setSearchInDescription: PropTypes.func.isRequired,
    showSearchBar: PropTypes.bool.isRequired,
    togglePreSearchModal: PropTypes.func.isRequired,
  };

  onVisibilityChange = (isVisibleFromComponent) => {
    const {
      isVisible,
      moduleColors,
      moduleData,
      setFullSizeCoverModuleData,
    } = this.props;

    if (isVisible !== isVisibleFromComponent) {
      setFullSizeCoverModuleData({
        id: moduleData.id,
        colors: moduleColors,
        isVisible: isVisibleFromComponent,
        headerLogo: moduleData.headerLogo,
      });
    }
  };

  render() {
    const {
      config,
      isPreSearchOpen,
      isSendingMaiaAudioSignal,
      onChange,
      onClick,
      onFocus,
      onSubmit,
      requestMaiaAudioSearch,
      searchInDescription,
      setSearchInDescription,
      showSearchBar,
      togglePreSearchModal,
      value,
    } = this.props;

    return (
      <Wrapper>
        <ReactVisibilitySensor
          offset={{ top: 0 }}
          minTopValue={0}
          partialVisibility
          onChange={this.onVisibilityChange}
        >
          {showSearchBar ? (
            <Search
              value={value}
              isPreSearchOpen={isPreSearchOpen}
              searchInDescription={searchInDescription}
              isSendingMaiaAudioSignal={isSendingMaiaAudioSignal}
              withGuidedSearch={_get(config, 'customisations.guidedSearch.active', false)}
              onFocus={onFocus}
              onClick={onClick}
              onChange={onChange}
              onSubmit={onSubmit}
              togglePreSearchModal={togglePreSearchModal}
              setSearchInDescription={setSearchInDescription}
              requestMaiaAudioSearch={requestMaiaAudioSearch}
            />
          ) : <SearchBarPlaceholder />}
        </ReactVisibilitySensor>
      </Wrapper>
    );
  }
}

export default withTranslation(['pages'])(SearchBar);

// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getValueByLocale } from './i18n';

// =============================
// Helpers
// =============================

export const dateToString = (date) => {
  if (!date) return '';
  return new Date(date).toISOString();
};

export const getPostCoverUrl = doc => _get(doc, 'coverImage.original.url');

export const getPostTitle = (doc, locale) => getValueByLocale(doc.title, locale);

export const getPostSecondTitle = (doc, locale) => getValueByLocale(doc.claim, locale);

export const getPostCoverLinkProps = doc => (doc.linkUrl ? { route: doc.linkUrl } : {});

export const getPostLinkProps = doc => (doc.linkUrl ? doc.linkUrl : null);

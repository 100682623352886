// =============================
// Imports
// =============================

import styled from 'styled-components';
import SearchBar from '../../../searchbar';

// =============================
// Styles
// =============================

export const Wrapper = styled.div``;

export const Search = styled(SearchBar)`
  align-items: center;
  display: flex;
  height: 6rem;
  margin: 0 auto;
  position: relative;
  left: 0;
  top: 0;
  transform: none;
  width: 75rem;
  z-index: ${({ theme }) => theme.zIndex.scSearchBar};

  > * {
    height: 6rem;
    width: 100% !important;
  }
`;

export const SearchBarPlaceholder = styled.div`
  height: 6rem;
  width: 75rem;
`;

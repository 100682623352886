// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Input from '../../inputs/input';
import Link from '../../../other/link';
import TextArea from '../../inputs/textarea';

import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }
`;

export const Container = styled.div`
  box-shadow: 0 1.5rem 8rem 1rem ${({ theme }) => theme.colors.boxShadow};
  width: 100%;

  ${({ theme }) => theme.mediaAbove.minWidth`
    display: flex;
    min-height: 45rem;
  `};
`;

export const StyledForm = styled.form`
  background-color: ${({ bg }) => bg};

  ${({ theme }) => theme.mediaBelow.minWidth`
    padding: 2.4rem ${paddingX};
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding: 4rem 3.2rem;
    width: 70%;
  `};
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-template-columns: 1fr;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: 1fr 1fr;
  `};
`;

export const Title = styled.div`
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 2.8rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 2.4rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 3.2rem;
  `};
`;

export const Informations = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaBelow.minWidth`
    padding: 2.4rem ${paddingX};
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding: 4rem 3.2rem;
    width: 30%;
  `};
`;

export const Information = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  justify-content: flex-start;
  line-height: 1.8rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 2.4rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 3.2rem;
  `};
`;

export const LocationIcon = styled(Icons.Location).attrs(({ theme }) => ({
  fill: theme.colors.primary,
  width: '2.4rem',
}))`
  margin-right: 1.2rem;
`;

export const PhoneIcon = styled(Icons.Phone).attrs(({ theme }) => ({
  fill: theme.colors.primary,
  width: '2.4rem',
}))`
  margin-right: 1.2rem;
`;

export const EmailIcon = styled(Icons.Email).attrs(({ theme }) => ({
  fill: theme.colors.primary,
  width: '2.4rem',
}))`
  margin-right: 1.2rem;
`;

export const StyledInput = styled(Input).attrs({
  containerClassName: 'containerClassName',
  variant: 'background',
})`
  & .containerClassName {
    background-color: ${({ theme }) => theme.colors.input.background};
    border: 0;
    color: ${({ theme }) => theme.colors.input.text};
    min-height: 4rem;

    input {
      color: ${({ theme }) => theme.colors.input.text};
    }
  }
`;

export const StyledTextArea = styled(TextArea).attrs(() => ({
  containerClassName: 'containerClassName',
}))`
  margin-bottom: 2rem;

  & .containerClassName {
    align-items: flex-start;
    background-color: ${({ theme }) => theme.colors.input.background};
    border: 0;
    color: ${({ theme }) => theme.colors.input.text};
    display: flex;
    justify-content: flex-start;
    height: 14rem;
    max-height: 14rem;
    overflow: hidden;

    > * {
      margin: 0.4rem 0;
    }
  }
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))``;

export const SocialsGrid = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  margin-top: auto;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-auto-columns: 2.4rem;
    grid-auto-flow: column;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    grid-auto-columns: 2.4rem;
    grid-auto-flow: column;
  `};
`;

export const SocialLink = styled(Icons.Plus).attrs(({ theme }) => ({
  fill: theme.colors.primary,
  width: '2rem',
}))``;

export const AppleIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmApple,
}))``;

export const DeezerIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmDeezer,
}))``;

export const FacebookIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmFacebook,
}))``;

export const InstagramIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmInstagram,
}))``;

export const LinkedinIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmLinkedin,
}))``;

export const SpotifyIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmSpotify,
}))``;

export const TwitterIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmTwitter,
}))``;

export const YoutubeIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmYoutube,
}))``;

// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Link from '../../../other/link';
import { textEditorContentStyles } from '../styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  ${({ placement }) => placement === 'right'
    && css`
      margin-left: auto;
    `};

  ${({ placement }) => placement === 'bottom'
    && css`
      margin-top: 2rem;
    `};
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const imageSize = '3rem';

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  ${({ theme }) => theme.mediaBelow.minWidth`
    height: 2rem;
    margin-right: 1.2rem;
    width: 2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 3rem;
    left: calc(-${imageSize} - 6.4rem);
    position: absolute;
    width: 3rem;
  `};
`;

export const Image = styled.img.attrs({
  loading: 'lazy',
})`
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
`;

export const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:not(:last-child) {
    margin-right: 2rem;
  }

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 2.4rem;
    line-height: 1.2;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 3.2rem;
    /** We use imageSize as the line-height so the image & the title are aligned. */
    line-height: ${imageSize};
  `};
`;

export const Description = styled.div`
  ${textEditorContentStyles};
  font-size: 1.4rem;
  line-height: 1.8rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  `};
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))``;

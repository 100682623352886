// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import getViewProps from '../../../../helpers/front/getviewprops';

import { Wrapper, PauseBtn, PlayBtn } from './styles';

// =============================
// Component
// =============================

function Controls({
  contextId,
  contextName,
  contextPosition,
  isPlaying,
  pause,
  play,
  trackId,
  ...rest
}) {
  return (
    <Wrapper {...getViewProps(rest)}>
      {isPlaying
        ? <PauseBtn onClick={pause} />
        : <PlayBtn onClick={() => play(trackId, contextName, contextId, contextPosition)} />}
    </Wrapper>
  );
}

Controls.propTypes = {
  /** Id of the player context */
  contextId: PropTypes.string,
  /** Name of the player context */
  contextName: PropTypes.string.isRequired,
  /** Position of the track within player context */
  contextPosition: PropTypes.number,
  isPlaying: PropTypes.bool.isRequired,
  pause: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  trackId: PropTypes.string.isRequired,
};

Controls.defaultProps = {
  contextPosition: null,
  contextId: null,
};

export default Controls;

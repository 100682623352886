// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons } from '@mewo/components';

import { motion } from 'framer-motion';
import { setSpacings } from '../../../../../helpers/modules';

import Button from '../../../button';
import Link from '../../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))``;

const animationProps = ({ animationDirection, theme }) => ({
  initial: {
    opacity: 0,
    x: animationDirection === 'forward' ? -100 : 100,
    transition: {
      duration: 1,
      ease: theme.transitionTiming.pose.easeOut,
    },
  },
  variants: {
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        ease: theme.transitionTiming.pose.easeOut,
      },
    },
  },
});

export const Container = styled(motion.div).attrs(() => ({
  animate: 'animate',
  variants: {
    animate: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  },
}))`
  display: flex;
  position: relative;
  width: 100%;
  /** Needed for before/after to be above WideBackground. */
  z-index: 0;

  ${({ enableBrandedHeader }) => enableBrandedHeader
    && css`
      &:before {
        background-color: ${({ theme }) => theme.colors.primary};
        content: '';
        display: block;
        height: ${({ spacings }) => `calc(13rem + ${spacings.padding.top / 10}rem)`};
        left: 0;
        margin-left: calc(-50vw + 50%);
        position: absolute;
        top: ${({ spacings }) => `-${spacings.padding.top / 10}rem`};
        width: 100vw;
        z-index: -1;
      }
    `};
`;

export const ImageContainer = styled.div`
  flex-shrink: 0;
  margin-right: 4rem;
  min-width: 0;
  position: relative;
  width: 40%;
`;

export const Image = styled(motion.span).attrs(({ alt, ...props }) => ({
  role: 'img',
  'aria-label': alt,
  ...animationProps(props),
}))`
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  display: block;
  left: 0;
  /** Image has a 3:2 ratio. */
  padding-top: 66.66%;
  top: 0;
  width: 100%;
`;

export const Content = styled.div`
  padding-top: 4rem;
  width: calc(60% - 4rem);
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 6.5rem;
  justify-content: space-between;
  margin-bottom: 4rem;
`;

export const Title = styled(motion.div).attrs(props => ({
  ...animationProps(props),
}))`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 4.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 100%;

  ${({ enableBrandedHeader }) => (enableBrandedHeader
    ? css`
          color: ${({ theme }) => theme.colors.primaryText};
        `
    : css`
          color: ${({ theme }) => theme.colors.text};
        `)};
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-left: 2rem;
  width: 8rem;
`;

export const PrevBtn = styled(Icons.ArrowDropdownOutline).attrs(
  ({ enableBrandedHeader, theme }) => ({
    dir: 'west',
    fill: enableBrandedHeader ? theme.colors.primaryText : theme.colors.text,
    width: '3.5rem',
  }),
)`
  cursor: pointer;
`;

export const NextBtn = styled(Icons.ArrowDropdownOutline).attrs(
  ({ enableBrandedHeader, theme }) => ({
    dir: 'east',
    fill: enableBrandedHeader ? theme.colors.primaryText : theme.colors.text,
    width: '3.5rem',
  }),
)`
  cursor: pointer;
`;

export const Description = styled(motion.div).attrs(props => ({
  ...animationProps(props),
}))`
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))`
  margin-top: 2rem;
`;

export const ButtonAnimationWrapper = styled(motion.div).attrs(props => ({
  ...animationProps(props),
}))``;

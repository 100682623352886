// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { Desktop, Mobile } from '../../../other/responsive';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  StyledImage,
  Content,
  Claim,
  Title,
  Description,
  StyledButton,
  StyledLink,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

const renderLink = (linkUrl, linkText, linkTextual) => {
  if (!linkUrl) return null;

  if (linkTextual) return <StyledLink href={linkUrl}>{linkText}</StyledLink>;

  return (
    <StyledLink href={linkUrl}>
      <StyledButton>{linkText}</StyledButton>
    </StyledLink>
  );
};

function TextImage({ colors, data, i18n: { language }, spacings }) {
  const title = getValueByLocale(data.title, language);
  const claim = getValueByLocale(data.claim, language);
  const description = getValueByLocale(data.descriptions, language);
  const { linkUrl } = data;
  const linkText = getValueByLocale(data.linkText, language);
  const imageSrc = _get(data, 'image.original.url');
  const imageSrcMobile = _get(data, 'imageMobile.original.url');
  const imageAlt = getValueByLocale(data.imageAlt, language);

  const {
    imageHorizontalPlacement,
    imageVerticalPlacement,
    imageWidth,
    imageWidthMobile,
    linkTextual,
  } = data;

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container
          imageHorizontalPlacement={imageHorizontalPlacement}
          imageVerticalPlacement={imageVerticalPlacement}
        >
          <Desktop>
            <StyledImage
              alt={imageAlt}
              imageHorizontalPlacement={imageHorizontalPlacement}
              imageVerticalPlacement={imageVerticalPlacement}
              src={imageSrc}
              width={imageWidth}
            />
          </Desktop>
          <Mobile>
            <StyledImage
              alt={imageAlt}
              src={imageSrcMobile || imageSrc}
              width={imageWidthMobile || imageWidth}
            />
          </Mobile>
          <Content>
            {claim && <Claim>{claim}</Claim>}
            {title && <Title>{title}</Title>}
            {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
            {renderLink(linkUrl, linkText, linkTextual)}
          </Content>
        </Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

TextImage.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    title: pth.descriptions,
    claim: pth.descriptions,
    descriptions: pth.descriptions,
    linkUrl: PropTypes.string,
    linkText: pth.descriptions,
    linkTextual: PropTypes.bool,
    image: pth.image,
    imagealt: pth.descriptions,
    imageMobile: pth.image,
    imageHorizontalPlacement: PropTypes.oneOf(['left', 'right']),
    imageVerticalPlacement: PropTypes.oneOf(['bottom', 'center', 'top']),
    imageWidth: PropTypes.number,
    imageWidthMobile: PropTypes.number,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(TextImage);

// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  color: ${({ theme }) => theme.colors.primary};
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-gap: 2rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `};

  ${({ hasMoreThanFiveClient, theme }) => theme.mediaAbove.minWidth`
    ${hasMoreThanFiveClient
    ? css`
        grid-template-columns: repeat(5, minmax(0, 1fr));
      `
    : css`
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
      `};

  `};
`;

export const Client = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
`;

export const ClientImgContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 12rem;
  justify-content: center;
  width: 100%;

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const ClientImg = styled.img.attrs({
  loading: 'lazy',
})`
  max-height: 100%;
  max-width: 100%;
`;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../config/i18n';

import { getValueByLocale } from '../../../../../helpers/i18n';
import * as pth from '../../../../../helpers/proptypes';

import { WideBackground } from '../../singleEntity/styles';

import {
  Wrapper,
  BenefitImgContainer,
  BenefitImg,
  BenefitDesc,
  BenefitTitle,
  StyledLink,
  Placeholder,
  LinkContainer,
  LineWrapper,
} from './styles';

// =============================
// Component
// =============================

function Benefits({ colors, data, i18n: { language }, spacings }) {
  if (!data.length) return null;

  const benefits = data.map((benefit) => {
    const title = getValueByLocale(benefit.title, language);
    const description = getValueByLocale(benefit.descriptions, language);
    const linkText = getValueByLocale(benefit.linkText, language);
    const { linkUrl } = benefit;
    const imageSrc = _get(benefit, 'image.original.url');
    const imageAlt = getValueByLocale(benefit.imageAlt, language);

    return {
      title,
      description,
      linkText,
      linkUrl,
      imageSrc,
      imageAlt,
    };
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />

        {benefits.map((benefit, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LineWrapper key={index}>
            {benefit.imageSrc ? (
              <BenefitImgContainer>
                <BenefitImg src={benefit.imageSrc} alt={benefit.imageAlt} />
              </BenefitImgContainer>
            ) : (
              <Placeholder />
            )}

            {benefit.title ? (
              <BenefitTitle>{benefit.title}</BenefitTitle>
            ) : (
              <Placeholder />
            )}

            {benefit.description ? (
              <BenefitDesc>{benefit.description}</BenefitDesc>
            ) : (
              <Placeholder />
            )}

            {benefit.linkUrl ? (
              <LinkContainer>
                <StyledLink href={benefit.linkUrl}>{benefit.linkText}</StyledLink>
              </LinkContainer>
            ) : (
              <Placeholder />
            )}
          </LineWrapper>
        ))}
      </Wrapper>
    </WithCustomTheme>
  );
}

Benefits.displayName = 'BenefitsMobile';

Benefits.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: pth.descriptions,
      descriptions: pth.descriptions,
      linkText: pth.descriptions,
      linkUrl: PropTypes.string,
      image: pth.image,
      imageAlt: pth.descriptions,
    }),
  ).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(Benefits);

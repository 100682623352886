/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import styled from 'styled-components';

import { Cover as CoverBase } from '../album/styles';

// =============================
// Styles
// =============================

export const Cover = styled(CoverBase).attrs({
  type: 'track',
  placeholderType: 'track',
})``;

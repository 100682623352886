// =============================
// Imports
// =============================

import styled, { css, keyframes } from 'styled-components';

// =============================
// Styles
// =============================

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 1rem, 0);
  }

  10% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  75% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
`;

const grow = keyframes`
  0% {
    height: 3rem;
  }

  10% {
    height: 3rem;
  }

  75% {
    height: 0;
  }

  100% {
    height: 0;
  }
`;

export const ScrollBar = styled.div`
  animation: ${grow} 3s ${({ theme }) => theme.transitionTiming.easeOut} 0s infinite;
  background-color: ${({ theme }) => theme.colors.text};
  height: 3rem;
  transform-origin: bottom center;
  transition: opacity ${({ theme }) => theme.transition.medium};
  width: 0.2rem;

  ${({ hasScrolled }) => hasScrolled
    && css`
      opacity: 0;
    `};
`;

export const Wrapper = styled.div`
  align-items: center;
  animation: ${fadeOut} 3s ${({ theme }) => theme.transitionTiming.easeOut} 0s infinite;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: flex-end;
  user-select: none;
  width: 5rem;

  ${({ hasScrolled }) => hasScrolled
    && css`
      pointer-events: none;
    `};
`;

export const ScrollText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 2rem;
  text-transform: uppercase;
  transition: opacity ${({ theme }) => theme.transition.medium};
  white-space: nowrap;

  ${({ hasScrolled }) => hasScrolled
    && css`
      opacity: 0;
    `};
`;

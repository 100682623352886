// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getTitle, getCoverUrl, getUrl, getBaseRoute } from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';

// Track uses the same styles as Album
import { Wrapper } from '../album/styles';
import { Cover } from './styles';
import { WideBackground } from '../styles';
import { getTrackAlbum } from '../../../../../helpers/entity/track';

// =============================
// Component
// =============================

function Track({ doc, locale, spacings, t }) {
  return (
    <Wrapper spacings={spacings}>
      <WideBackground />
      <Cover
        contextName={playerCtx.SEARCH}
        linkProps={getUrl('tracks')(doc) ? {
          route: getBaseRoute('tracks'),
          nextAs: getUrl('tracks')(doc),
        } : {}}
        description={getValueByLocale(doc.descriptions, locale)}
        key={doc.id}
        preText={t('pages:custom_page.track')}
        src={getCoverUrl('tracks')(doc)}
        title={getTitle('tracks')(doc)}
        alt={getTrackAlbum(doc).title}
      />
    </Wrapper>
  );
}

Track.propTypes = {
  doc: PropTypes.shape({
    album: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      image: pth.image,
    }),
    catalog: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ]),
    displayTitle: PropTypes.string,
    displayArtists: pth.displayartists,
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    version: pth.trackVersionBase,
    descriptions: pth.descriptions,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default Track;

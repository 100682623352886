/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Cover as CoverBase } from '../artist/styles';

// =============================
// Styles
// =============================

export const Cover = styled(CoverBase).attrs({
  placeholderType: 'publisher',
  type: 'publisher',
})``;

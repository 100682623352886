// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;

  ${({ placement }) => placement === 'left' && css`
    justify-content: flex-start;
  `};

  ${({ placement }) => placement === 'center' && css`
    justify-content: center;
  `};

  ${({ placement }) => placement === 'right' && css`
    justify-content: flex-end;
  `};
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))``;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'medium',
}))``;

// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _find from 'lodash/find';
import { withRouter } from 'next/router';

import {
  getPreSearchData,
  setSearchValue,
  resetPreSearchData,
  requestMaiaTextSearch,
  clearFullSizeCoverModuleData,
} from '../../../../store/actions/SearchActions';

import FullSizeCover from '../../../presentationals/modules/fullSizeCover';

// =============================
// Component
// ============================

function mapStateToProps({ core, modals, search }, { data }) {
  return {
    privacyCookies: core.privacyCookies,
    isPreSearchOpen: modals.isPreSearchOpen,
    isPreSearchFetchedOnce: search.preSearch.isFetchedOnce,
    searchValue: search.preSearch.searchValue,
    searchQuery: search.tracksSearch.query,
    initialFacets: search.tracksSearch.data.initialFacets,
    preSearchDatas: search.preSearch.data,
    isVisible: _find(
      search.fullSizeCoverModule, item => item.id === data.id,
    )?.isVisible || false,
  };
}

export default connect(mapStateToProps, {
  getPreSearchData,
  setSearchValue,
  resetPreSearchData,
  requestMaiaTextSearch,
  clearFullSizeCoverModuleData,
})(withRouter(FullSizeCover));

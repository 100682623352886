// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import Cover from '../../../containers/cover';
import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const CoverBase = styled(Cover).attrs({
  lazyload: false,
  LinkComponent: Link,
})``;

export const WideBackground = styled.div`
  background-color: ${({ theme }) => (theme.colors.useCustomColor ? theme.colors.background : 'transparent')};
  height: 100%;
  left: 0;
  margin-left: calc(-50vw + 50%);
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;

  ${({ color }) => color && css`
    background-color: ${color};
  `};
`;

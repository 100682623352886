// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

import Link from '../../../other/link';

import { setSpacings } from '../../../../helpers/modules';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  margin: 0 0.8rem;
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }
`;

export const Socials = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const SocialLink = styled(Icons.Plus).attrs(({ theme }) => ({
  fill: theme.colors.primary,
  width: '2.2rem',
}))``;

export const AppleIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmApple,
}))``;

export const DeezerIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmDeezer,
}))``;

export const FacebookIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmFacebook,
}))``;

export const InstagramIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmInstagram,
}))``;

export const LinkedinIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmLinkedin,
}))``;

export const SpotifyIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmSpotify,
}))``;

export const TwitterIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmTwitter,
}))``;

export const YoutubeIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmYoutube,
}))``;

// =============================
// Imports
// =============================

import styled, { keyframes } from 'styled-components';
import { alpha } from '@material-ui/core';

import Button from '../../button';
import Cover from '../../../containers/cover';
import ScrollIndicator from '../../scrollIndicator';

import { headerHeight } from '../../header/mobile/variables';
import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  margin-left: calc(-50vw + 50%);
  position: relative;
  width: 100vw;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 100vh;
    /** On small screen we need to have at least 720px. */
    min-height: 72rem;
  `};
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 100%;
    margin-left: 40%;
    padding-left: calc(16rem + 5rem);
    padding-right: 10%;
    width: 60vw;
  `};
`;

const backgroundAnimation = keyframes`
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
`;

export const ContentBackground = styled.div`
  animation: ${backgroundAnimation} ${({ theme }) => theme.transitionTiming.easeInOut} 700ms 300ms
    forwards;
  background-color: ${({ theme, useCustomColor }) => (useCustomColor ? alpha(theme.colors.background, 0.1) : alpha(theme.colors.text, 0.1))};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  transform-origin: left;
  top: 0;
  z-index: -1;

  /* Pre animation styles */
  transform: scaleX(0);
`;

export const Discover = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 0.8rem;
  opacity: 0.5;

  ${({ theme }) => theme.mediaAbove.sxga`
    font-size: 1.3rem;
  `};
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 2.8rem;
    line-height: 1.3;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 3.5rem;
    line-height: 3.5rem;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    font-size: 4.5rem;
    line-height: 5.4rem;
    margin-bottom: 2rem;
  `};
`;

export const SecondTitle = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  line-height: 1.8rem;
  margin-bottom: 2rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaAbove.sxga`
    font-size: 1.5rem;
    margin-bottom: 4rem;
  `};
`;

export const Description = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.3;
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.4rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.5rem;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-bottom: 5rem;
  `};
`;

const coverAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.1) translate3d(-8.5rem, -3rem, 0);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
`;

export const StyledCover = styled(Cover)`
  z-index: 1;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-top: ${headerHeight};
    padding: ${paddingX};
    padding-bottom: 0;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    animation: ${coverAnimation} cubic-bezier(0.22, 0.61, 0.35, 1) 1200ms 300ms forwards;
    height: 40rem;
    left: -30rem;
    position: absolute;
    top: calc(50% - 20rem);
    width: 40rem;

    /** Pre animation styles */
    opacity: 0;
    transform: scale(1.1) translate3d(-8.5rem, -3rem, 0);
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    height: 50rem;
    left: -40rem;
    top: calc(50% - 25rem);
    width: 50rem;
  `};

  ${({ theme }) => theme.mediaAbove.uxga`
    height: 60rem;
    left: -50rem;
    top: calc(50% - 30rem);
    width: 60rem;
  `};

  ${({ theme }) => theme.mediaAbove.wuxga`
    height: 65rem;
    left: -55rem;
    top: calc(50% - 32.5rem);
    width: 65rem;
  `};
`;

const informationAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-8rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const InformationWrapper = styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    padding: ${paddingX};
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    animation: ${informationAnimation} cubic-bezier(0.22, 0.61, 0.35, 1) 900ms 700ms forwards;

    /* Pre animation styles */
    opacity: 0;
    transform: translateX(-8rem) translateZ(0rem);
  `};
`;

export const StyledButton = styled(Button).attrs({
  color: 'primary',
  size: 'medium',
})``;

export const StyledScrollIndicator = styled(ScrollIndicator)`
  bottom: 4rem;
  left: calc(-2.5rem + 10vw);
  position: absolute;
`;

/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import styled from 'styled-components';

import { Cover as CoverBase } from '../singleEntity/artist/styles';

// =============================
// Styles
// =============================

export const Cover = styled(CoverBase).attrs({
  placeholderType: 'post',
  type: 'post',
})``;

// =============================
// Imports
// =============================

import styled from 'styled-components';

import Link from '../../../../other/link';

import { setSpacings } from '../../../../../helpers/modules';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))``;

export const Container = styled.div``;

/** This image has a 16:9 ratio. */
export const StyledImage = styled.span.attrs(({ alt }) => ({
  'aria-label': alt,
  role: 'img',
}))`
  align-items: center;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: calc(100vw * (9 / 16));
  justify-content: center;
  margin-left: calc(-50vw + 50%);
  position: relative;
  width: 100vw;
  z-index: 0;
`;

export const Title = styled.div`
  font-size: 4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 1.2rem;
  text-align: center;
`;

export const Breadcrumb = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  left: 1.2rem;
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  z-index: 1;
`;

export const Crumb = styled.div`
  &:not(:last-child) {
    &:after {
      content: '>';
      margin: 0 0.4rem;
    }
  }
`;

// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

import { setSpacings } from '../../../../../helpers/modules';

import CoverBase from '../../../../containers/cover';

import Button from '../../../button';
import Link from '../../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  margin-top: 2rem;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Cover = styled(CoverBase).attrs({
  classNames: {
    cover: 'coverClassName',
    title: 'titleClassName',
    type: 'typeClassName',
  },
  lazyload: false,
  LinkComponent: Link,
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  playButtonSize: 'musicItem',
})`
  flex-shrink: 0;
  margin-right: 1.2rem;
  min-width: 0;
  position: relative;
  width: 37%;

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-right: 3.2rem;
  `};
`;

export const Content = styled.div`
  flex-shrink: 0;
  margin-right: 1.2rem;
  max-width: 42rem;
  width: 40%;

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-right: 17rem;
  `};
`;

export const Type = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  opacity: 0.5;
  width: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 0.8rem;
  width: 100%;

  ${({ theme }) => theme.mediaAbove.wuxga`
    margin-bottom: 3.2rem;
  `};
`;

export const Tracks = styled.div`
  display: grid;
  grid-gap: 0.8rem;

  ${({ theme }) => theme.mediaAbove.wuxga`
    grid-gap: 1.2rem;
  `};
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'medium',
}))``;

export const Preview = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  width: 25%;
  max-width: 20rem;
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-left: auto;
  width: 9rem;
`;

export const PrevBtn = styled(Icons.ArrowDropdownOutline).attrs(() => ({
  dir: 'west',
  width: '3.5rem',
}))`
  cursor: pointer;
`;

export const NextBtn = styled(Icons.ArrowDropdownOutline).attrs(() => ({
  dir: 'east',
  width: '3.5rem',
}))`
  cursor: pointer;
`;

export const PreviewCover = styled(CoverBase).attrs({
  classNames: {
    cover: 'coverClassName',
    title: 'titleClassName',
    type: 'typeClassName',
  },
  lazyload: false,
  LinkComponent: Link,
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  playButtonSize: 'musicItem',
})`
  & .coverClassName:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  & .titleClassName {
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    line-height: 1.8rem;
  }

  & .typeClassName {
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    line-height: 1.8rem;

    &:not(:last-child) {
      margin-bottom: 0.4rem;
    }
  }
`;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../config/i18n';

import { getValueByLocale } from '../../../../../helpers/i18n';
import * as pth from '../../../../../helpers/proptypes';

import { WideBackground } from '../../singleEntity/styles';

import {
  Wrapper,
  Grid,
  BenefitImgContainer,
  BenefitImg,
  BenefitDesc,
  BenefitTitle,
  StyledLink,
  Placeholder,
  LinkContainer,
  ImageGrid,
  TitleGrid,
  DescGrid,
} from './styles';

// =============================
// Component
// =============================

function Benefits({ colors, data, i18n: { language }, spacings }) {
  if (!data.length) return null;

  const benefits = data.map((benefit) => {
    const title = getValueByLocale(benefit.title, language);
    const description = getValueByLocale(benefit.descriptions, language);
    const linkText = getValueByLocale(benefit.linkText, language);
    const { linkUrl } = benefit;
    const imageSrc = _get(benefit, 'image.original.url');
    const imageAlt = getValueByLocale(benefit.imageAlt, language);

    return {
      title,
      description,
      linkText,
      linkUrl,
      imageSrc,
      imageAlt,
    };
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <ImageGrid benefitsLength={benefits.length}>
          {benefits.map(({ imageSrc, imageAlt }, index) => (imageSrc ? (
            // eslint-disable-next-line react/no-array-index-key
            <BenefitImgContainer key={index}>
              <BenefitImg src={imageSrc} alt={imageAlt} />
            </BenefitImgContainer>
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <Placeholder key={index} />
          )),
          )}
        </ImageGrid>
        <TitleGrid benefitsLength={benefits.length}>
          {benefits.map(({ title }, index) => (title
            // eslint-disable-next-line react/no-array-index-key
            ? <BenefitTitle key={index}>{title}</BenefitTitle> : <Placeholder key={index} />),
          )}
        </TitleGrid>
        <DescGrid benefitsLength={benefits.length}>
          {benefits.map(({ description }, index) => (description
            // eslint-disable-next-line react/no-array-index-key
            ? <BenefitDesc key={index}>{description}</BenefitDesc> : <Placeholder key={index} />),
          )}
        </DescGrid>
        <Grid benefitsLength={benefits.length}>
          {benefits.map(({ linkUrl, linkText }, index) => (linkUrl ? (
            // eslint-disable-next-line react/no-array-index-key
            <LinkContainer key={index}>
              <StyledLink href={linkUrl}>{linkText}</StyledLink>
            </LinkContainer>
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <Placeholder key={index} />
          )),
          )}
        </Grid>
      </Wrapper>
    </WithCustomTheme>
  );
}

Benefits.displayName = 'BenefitsDesktop';

Benefits.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: pth.descriptions,
      descriptions: pth.descriptions,
      linkText: pth.descriptions,
      linkUrl: PropTypes.string,
      image: pth.image,
      imageAlt: pth.descriptions,
    }),
  ).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(Benefits);

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Grid,
  Item,
  Title,
  CompanyName,
  CompanyNameType,
  SubTitle,
  TitleSubTitle,
  Content,
  Type,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function MasonryVideo({ colors, data, i18n: { language }, spacings, toggleVideoModal }) {
  const videos = data.map((item) => {
    const video = {
      id: item.id,
      title: getValueByLocale(item.title, language),
      videoLink: item.videoLink,
      subTitle: getValueByLocale(item.subTitle, language),
      type: getValueByLocale(item.type, language),
      companyName: item.companyName,
      description: getValueByLocale(item.descriptions, language),
      thumbnail: _get(item, 'thumbnail.original.url'),
      thumbnailAlt: getValueByLocale(item.thumbnailAlt, language),
      trackData: item.trackData,
    };

    return (
      <Item
        key={video.id}
        onClick={() => toggleVideoModal(true, {
          trackData: item.trackData,
          videoData: {
            title: video.title,
            description: video.description,
            url: video.videoLink,
          },
        })}
        src={video.thumbnail}
        alt={video.thumbnailAlt}
      >
        <Content>
          <CompanyNameType>
            <CompanyName>{video.companyName}</CompanyName>
            <Type>{video.type}</Type>
          </CompanyNameType>
          <TitleSubTitle>
            <Title>{video.title}</Title>
            <SubTitle>{video.subTitle}</SubTitle>
          </TitleSubTitle>
        </Content>
      </Item>
    );
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Grid>{videos.map(item => item)}</Grid>
      </Wrapper>
    </WithCustomTheme>
  );
}

MasonryVideo.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: pth.descriptions,
      videoLink: PropTypes.string,
      subTitle: pth.descriptions,
      type: pth.descriptions,
      companyName: PropTypes.string,
      descriptions: pth.descriptions,
      thumbnail: pth.image,
      thumbnailAlt: pth.descriptions,
      trackData: pth.smalltrack,
    }),
  ).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  toggleVideoModal: PropTypes.func.isRequired,
};

export default withTranslation(['pages'])(MasonryVideo);

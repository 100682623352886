// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div``;

export const PlayBtn = styled(Icons.PlayOutline).attrs(() => ({
  width: '4rem',
}))``;

export const PauseBtn = styled(Icons.PauseOutline).attrs(() => ({
  width: '4rem',
}))``;

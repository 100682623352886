// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../../other/responsive';

import TrendingWithTracksMobile from './mobile';
import TrendingWithTracksDesktop from './desktop';

// =============================
// Component
// =============================

class TrendingWithTracks extends PureComponent {
  static displayName = 'TrendingWithTracksResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={TrendingWithTracksDesktop}
        MobileComponent={TrendingWithTracksMobile}
        props={this.props}
      />
    );
  }
}

export default TrendingWithTracks;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import { Wrapper, ScrollText, ScrollBar } from './styles';

// =============================
// Component
// =============================

const ScrollIndicator = ({ className, visible, onClick, t }) => (
  <Wrapper className={className} hasScrolled={!visible} initialPose="visible" onClick={onClick} pose="visible">
    <ScrollText hasScrolled={!visible}>{t('components:scroll_indicator.scroll')}</ScrollText>
    <ScrollBar hasScrolled={!visible} initialPose="visible" pose="visible" />
  </Wrapper>
);

ScrollIndicator.propTypes = {
  className: PropTypes.string,
  /** Handle onClick event. */
  onClick: PropTypes.func.isRequired,
  /** Translation strings. */
  t: PropTypes.func.isRequired,
  /** If True, user has scrolled. */
  visible: PropTypes.bool,
};

ScrollIndicator.defaultProps = {
  className: '',
  visible: false,
};

export default withTranslation(['components'])(ScrollIndicator);

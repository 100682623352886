// =============================
// Imports
// =============================

import styled from 'styled-components';

import Link from '../../../other/link';

import { setSpacings } from '../../../../helpers/modules';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const Container = styled.div`
  display: grid;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-auto-columns: 1fr;
    grid-gap: 4rem;
  `}

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-auto-columns: minmax(0, 49rem);
    grid-auto-flow: column;
    grid-gap: 10rem;
  `}
`;

export const Benefit = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding: 0 1.2rem;
  `}

  ${({ theme }) => theme.mediaAbove.sxga`
    padding: 0;
  `}
`;

export const BenefitImgContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 4rem;
  justify-content: flex-start;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }
`;

export const BenefitImg = styled.img.attrs({
  loading: 'lazy',
})`
  max-height: 100%;
  max-width: 100%;
`;

export const BenefitTitle = styled.div`
  font-size: 3.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const BenefitDesc = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

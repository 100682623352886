// =============================
// Imports
// =============================

import styled from 'styled-components';

import MusicItem from '../../../containers/musicitem';

import { setSpacings } from '../../../../helpers/modules';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  column-gap: 5rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 0;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 2rem;
  `};
`;

export const StyledMusicItem = styled(MusicItem).attrs(() => ({
  coverStyle: 'cover',
  disableExtraOptions: true,
  withActions: true,
  withDuration: true,
  withVersions: false,
}))``;

// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../../other/responsive';

import SliderMobile from './mobile';
import SliderDesktop from './desktop';

// =============================
// Component
// =============================

class Slider extends PureComponent {
  static displayName = 'SliderResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={SliderDesktop}
        MobileComponent={SliderMobile}
        props={this.props}
      />
    );
  }
}

export default Slider;

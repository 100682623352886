/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import { css } from 'styled-components';

// =============================
// Helpers
// =============================

/**
 * All spacings coming from API are expressed in pixels and
 * we use rem within the app
 */
export const setSpacings = spacings => css`
  margin-bottom: ${spacings.margin.bottom / 10}rem;
  margin-top: ${spacings.margin.top / 10}rem;
  padding-bottom: ${spacings.padding.bottom / 10}rem;
  padding-top: ${spacings.padding.top / 10}rem;
`;

// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Cover from '../../../containers/cover';
import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  noHover: true,
  textEllipsis: true,
}))`
  display: inline;
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.75;
  }
`;

export const Grid = styled.div`
  display: grid;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 1.2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2rem;
  `};
`;

export const Playlist = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const TitleDate = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const UpdatedAt = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const PlaylistCover = styled(Cover).attrs({
  classNames: {
    title: 'titleClassName',
    type: 'typeClassName',
  },
  lazyload: false,
  LinkComponent: Link,
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  type: 'playlist',
  placeholderType: 'playlist',
  playButtonSize: 'musicItem',
})`
  margin-right: 2rem;
  width: 20%;
`;

// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { subscribeToNewsletter, modifyUserInfo } from '../../../../store/actions/UserActions';

import Newsletter from '../../../presentationals/modules/newsletter';

// =============================
// Component
// =============================

function mapStateToProps({ user }) {
  return {
    user: user.data,
    isLogged: user.isLogged,
  };
}

export default connect(mapStateToProps, {
  modifyUserInfo,
  subscribeToNewsletter,
})(Newsletter);

// =============================
// Imports
// =============================

import { Component, forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';

import { withTranslation } from '../../../config/i18n';

import Controls from './controls';
import { Desktop, Mobile } from '../../other/responsive';

import getViewProps from '../../../helpers/front/getviewprops';
import { getTitle, getCoverUrl, getUrl, getBaseRoute } from '../../../helpers/entity';
import { getTrackAlbum, getTrackDisplayArtists, getTrackTitle } from '../../../helpers/entity/track';
import { presentDuration } from '../../../helpers/entity/common';
import * as pth from '../../../helpers/proptypes';

import { Option, OptionLink } from '../dropdown/styles';
import {
  Actions,
  AddToPlaylistIcon,
  Duration,
  StyledAddToPlaylist,
  StyledLink,
  StyledTooltip,
  SubTitle,
  Title,
  TitleSubTitle,
  Wrapper,
  StyledDropdownButton,
  ThreeDotIcon,
  TrackCover,
} from './styles';

// =============================
// Component
// =============================

class MusicItemMini extends Component {
  static propTypes = {
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    /** Id of the player context */
    contextId: PropTypes.string,
    /** Name of the player context */
    contextName: PropTypes.string.isRequired,
    /** Position of the track within player context */
    contextPosition: PropTypes.number,
    /** Force cover disabling */
    coverDisabled: PropTypes.bool,
    /** Function to trigger download copyright */
    downloadCopyright: PropTypes.func.isRequired,
    /** Used to pass ref to parent */
    innerRef: PropTypes.func,
    /** Asserts if the track is current */
    isCurrent: PropTypes.func.isRequired,
    /** User is logged */
    isLogged: PropTypes.bool.isRequired,
    /** Asserts if the track is being played */
    isPlaying: PropTypes.func.isRequired,
    /** Pause track */
    pause: PropTypes.func.isRequired,
    /** Play track */
    play: PropTypes.func.isRequired,
    /** Open track lyrics modal */
    setTrackLyricsOpen: PropTypes.func.isRequired,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Music object */
    track: pth.smalltrack.isRequired,
  };

  static displayName = 'MusicItemMini';

  static defaultProps = {
    className: '',
    contextPosition: null,
    contextId: null,
    coverDisabled: false,
    innerRef: null,
  };

  dropdownButtonRenderOption = (option) => {
    const { isLogged } = this.props;

    if (option.authRequired && !isLogged) {
      return <OptionLink route="/login">{option.label}</OptionLink>;
    }

    return <Option>{option.label}</Option>;
  };

  handlePlayPause = () => {
    const {
      contextId,
      contextName,
      contextPosition,
      isCurrent: isCurrentFnc,
      isPlaying: isPlayingFnc,
      pause,
      play,
      track,
    } = this.props;

    // Check if track is playing
    const isCurrent = isCurrentFnc(track.id, contextName, contextId);
    const isPlaying = isPlayingFnc(track.id, contextName, contextId);

    if (isPlaying) {
      return pause();
    }

    if (isCurrent) {
      return play();
    }

    return play(track.id, contextName, contextId, contextPosition);
  };

  renderDropdownButton = () => {
    const { downloadCopyright, isLogged, setTrackLyricsOpen, t, track } = this.props;

    const options = [];

    // Show lyrics
    if (_get(track, 'lyrics')) {
      options.push({
        action: () => setTrackLyricsOpen(true, track),
        label: t('components:music_item.see_lyrics'),
        name: 'see_lyrics',
      });
    }

    // Download Copyright
    options.push({
      action: isLogged
        ? () => downloadCopyright('track', track.id, getTitle('tracks')(track))
        : null,
      label: t('components:music_item.download_copyright'),
      name: 'download_copyright',
      authRequired: true,
    });

    return (
      <StyledDropdownButton
        noButton
        options={options}
        renderOption={this.dropdownButtonRenderOption}
        togglerElement={(
          <StyledTooltip content={t('components:music_item.other_actions')}>
            <ThreeDotIcon />
          </StyledTooltip>
        )}
      />
    );
  };

  render() {
    const {
      className,
      contextId,
      contextName,
      contextPosition,
      coverDisabled,
      innerRef,
      isLogged,
      isCurrent,
      isPlaying,
      pause,
      play,
      t,
      track,
      ...rest
    } = this.props;

    const displayArtists = getTrackDisplayArtists(track);

    const subTitle = displayArtists.reduce((acc, da, i) => {
      acc.push(
        <StyledLink
          inline
          // eslint-disable-next-line react/no-array-index-key
          key={`da-${da.id}-${i}`}
          nextAs={getUrl('artists')(da, da.name)}
          route={getBaseRoute('artists')}
          textEllipsis
        >
          {da.name}
        </StyledLink>,
      );

      if (i < displayArtists.length - 1) acc.push(', ');

      return acc;
    }, []);

    const { duration } = track;

    // Show controls instead of cover when there is a context position
    // as covers do not know how to handle them
    const noCover = coverDisabled || _isNumber(contextPosition);

    return (
      <Wrapper className={className} noCover={noCover} ref={innerRef} {...getViewProps(rest)}>
        {noCover ? (
          <Controls
            contextId={contextId}
            contextName={contextName}
            contextPosition={contextPosition}
            isPlaying={isPlaying(track.id, contextName, contextId, contextPosition)}
            pause={pause}
            play={play}
            trackId={track.id}
          />
        ) : (
          <Fragment>
            <Desktop>
              <TrackCover
                contextId={contextId}
                contextName={contextName}
                isCurrent={isCurrent(track.id, contextName, contextId)}
                isPlaying={isPlaying(track.id, contextName, contextId, contextPosition)}
                linkProps={getUrl('tracks')(track) ? {
                  route: getBaseRoute('tracks'),
                  nextAs: getUrl('tracks')(track),
                } : {}}
                onPause={this.handlePlayPause}
                onPlay={this.handlePlayPause}
                src={getCoverUrl('tracks')(track, 'small')}
                alt={getTrackAlbum(track).title}
              />
            </Desktop>
            <Mobile>
              <TrackCover
                contextId={contextId}
                contextName={contextName}
                linkProps={
                  getUrl('tracks')(track)
                    ? {
                      route: getBaseRoute('tracks'),
                      nextAs: getUrl('tracks')(track),
                    }
                    : {}
                }
                src={getCoverUrl('tracks')(track, 'small')}
                alt={getTrackAlbum(track).title}
              />
            </Mobile>
          </Fragment>
        )}
        <TitleSubTitle>
          <StyledTooltip content={getTitle('tracks')(track)} fullWidth>
            {getUrl('tracks')(track) ? (
              <StyledLink inline route={getBaseRoute('tracks')} nextAs={getUrl('tracks')(track)}>
                <Title>{getTrackTitle(track)}</Title>
              </StyledLink>
            ) : (
              <Title>{getTrackTitle(track)}</Title>
            )}
          </StyledTooltip>
          <SubTitle noCover={noCover}>{subTitle}</SubTitle>
        </TitleSubTitle>
        <Duration>{duration && presentDuration(duration)}</Duration>
        <Actions>
          {isLogged ? (
            <StyledAddToPlaylist
              itemId={track.id}
              togglerElement={(
                <StyledTooltip content={t('components:music_item.add_to_playlist')}>
                  <AddToPlaylistIcon />
                </StyledTooltip>
              )}
              type="track"
            />
          ) : (
            <StyledTooltip content={t('components:music_item.add_to_playlist')}>
              <StyledLink noHover route="/login">
                <AddToPlaylistIcon />
              </StyledLink>
            </StyledTooltip>
          )}
          {this.renderDropdownButton()}
        </Actions>
      </Wrapper>
    );
  }
}

function forwardedRef({ i18n: { language }, ...props }, ref) {
  return <MusicItemMini locale={language} innerRef={ref} {...props} />;
}

forwardedRef.displayName = MusicItemMini.displayName;

export default withTranslation('components')(forwardRef(forwardedRef));

// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import { Cover } from '../singleEntity/album/styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    justify-content: space-around;
  `};
`;

export const AlbumCover = styled(Cover).attrs({
  placeholderType: 'album',
  type: 'album',
  vynil: true,
})``;

export const ArtistCover = styled(Cover).attrs({
  placeholderType: 'artist',
  type: 'artist',
})``;

export const CatalogCover = styled(Cover).attrs({
  placeholderType: 'catalog',
  type: 'catalog',
})``;

export const LabelCover = styled(Cover).attrs({
  placeholderType: 'label',
  type: 'label',
})``;

export const PlaylistCover = styled(Cover).attrs({
  placeholderType: 'playlist',
  type: 'playlist',
})``;

export const PublisherCover = styled(Cover).attrs({
  placeholderType: 'publisher',
  type: 'publisher',
})``;

export const TrackCover = styled(Cover).attrs({
  placeholderType: 'track',
  type: 'track',
})``;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import Link from '../../../other/link';

import {
  getPostCoverUrl,
  getPostCoverLinkProps,
  getPostSecondTitle,
  getPostTitle,
  getPostLinkProps,
} from '../../../../helpers/doc';
import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

// Post uses the same styles as SingleEntity Artist (& catalog).
import {
  Wrapper,
  Container,
  Informations,
  Type,
  Name,
  Description,
  DiscoverButton,
} from '../singleEntity/artist/styles';
import { Cover } from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function Post({ colors, doc, i18n: { language }, spacings, t }) {
  const description = getValueByLocale(doc.descriptions, language);
  const secondTitle = getPostSecondTitle(doc, language);

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container>
          <Cover
            linkProps={getPostCoverLinkProps(doc)}
            src={getPostCoverUrl(doc)}
            alt={getValueByLocale(doc.coverImageAlt, language)}
          />
          <Informations>
            {!!secondTitle && <Type>{secondTitle}</Type>}
            <Name>{getPostTitle(doc)}</Name>
            {!!description && <Description>{description}</Description>}
            {getPostLinkProps(doc) && (
              <Link route={getPostLinkProps(doc)}>
                <DiscoverButton>{t('pages:custom_page.click_here')}</DiscoverButton>
              </Link>
            )}
          </Informations>
        </Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

Post.propTypes = {
  colors: pth.colors.isRequired,
  doc: PropTypes.shape({
    title: pth.descriptions,
    claim: pth.descriptions,
    descriptions: pth.descriptions,
    linkUrl: PropTypes.string,
    coverImage: pth.image,
    coverImageAlt: pth.descriptions,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['pages'])(Post);

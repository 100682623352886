// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledImage = styled.img.attrs({
  loading: 'lazy',
})`
  height: auto;
  position: relative;
  width: ${({ width }) => width}%;

  ${({ fullWidth }) => fullWidth && css`
    margin-left: calc(-50vw + 50%);
    width: 100vw;
  `};
`;

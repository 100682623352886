// =============================
// Imports
// =============================

import {
  required,
  composeValidators,
  mustBeEmail,
} from '../index';

// =============================
// Validator
// =============================

const validator = {
  firstName: required,
  lastName: required,
  email: composeValidators(required, mustBeEmail),
  subject: required,
  message: required,
};

export default validator;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  StyledButton,
  StyledLink,
  Container,
  Image,
  Content,
  Texts,
  Title,
  LabelImage,
  LabelDesc,
  Label,
  TopOuterBackground,
  LabelImageContainer,
} from './styles';

// =============================
// Component
// =============================

/*
  Notes:
  CallToAction has no WideBackground because the background-color
  Only apply to Container.
*/
function CallToAction({ colors, data, i18n: { language }, spacings }) {
  const title = getValueByLocale(data.title, language);
  const imageSrc = _get(data, 'image.original.url');
  const imageAlt = getValueByLocale(data.imageAlt, language);
  const hasLink = !!data.linkUrl;
  const linkText = getValueByLocale(data.linkText, language);

  const { benefits, outerBackgroundBottom, outerBackgroundTop } = data;

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <Container
          outerBackgroundBottom={outerBackgroundBottom}
          outerBackgroundTop={outerBackgroundTop}
          spacings={spacings}
        >
          <TopOuterBackground outerBackgroundTop={outerBackgroundTop} spacings={spacings} />
          <Content>
            <Image alt={imageAlt} src={imageSrc} />
            <Texts>
              {title && <Title>{title}</Title>}
              {benefits.map((benefit) => {
                const labelDesc = getValueByLocale(benefit.descriptions, language);
                const labelImg = _get(benefit, 'image.original.url');
                const labelImgAlt = getValueByLocale(benefit.imageAlt, language);

                if (!labelDesc) return null;

                return (
                  <Label key={benefit.id}>
                    {labelImg && (
                      <LabelImageContainer>
                        <LabelImage alt={labelImgAlt} src={labelImg} />
                      </LabelImageContainer>
                    )}
                    <LabelDesc>{labelDesc}</LabelDesc>
                  </Label>
                );
              })}
              {hasLink && (
                <StyledLink href={data.linkUrl}>
                  <StyledButton>{linkText}</StyledButton>
                </StyledLink>
              )}
            </Texts>
          </Content>
        </Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

CallToAction.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    title: pth.descriptions,
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        descriptions: pth.descriptions,
        image: pth.image,
        imageAlt: pth.descriptions,
      }),
    ),
    linkUrl: PropTypes.string,
    linkText: pth.descriptions,
    outerBackgroundBottom: PropTypes.string,
    outerBackgroundTop: PropTypes.string,
    image: pth.image,
    imageAlt: pth.descriptions,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(CallToAction);

// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _includes from 'lodash/includes';
import _slice from 'lodash/slice';

import { withTranslation } from '../../../../../config/i18n';

import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import { getBaseRoute, getCoverUrl, getTitle, getUrl } from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';

import { WideBackground } from '../../singleEntity/styles';
import {
  Wrapper,
  Container,
  Content,
  Title,
  PrevBtn,
  NextBtn,
  StyledLink,
  StyledButton,
  Type,
  Tracks,
  Header,
  Cover,
  StyledMusicItem,
} from './styles';

// =============================
// Component
// =============================

class TrendingWithTracks extends Component {
  static displayName = 'TrendingWithTracksMobile';

  static propTypes = {
    colors: pth.colorsModule.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        // Entity ID.
        id: PropTypes.string,
        // Entity type.
        type: PropTypes.string,
        // Entity data.
        data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        // Tracks from Entity.
        tracks: PropTypes.arrayOf(pth.smalltrack),
      }),
    ).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    spacings: pth.spacings.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    current: 0,
  };

  getCurrentData = () => {
    const { data, t } = this.props;
    const { current } = this.state;

    const curentData = {
      id: data[current].id,
      title: getTitle(`${data[current].type}s`)(data[current].data),
      type: t(`pages:custom_page.${data[current].type}`),
      tracks: data[current].tracks,
      linkText: t(`pages:custom_page.discover_${data[current].type}`),
      coverProps: {
        alt: getTitle(`${data[current].type}s`)(data[current].data),
        linkProps: {
          route: getBaseRoute(`${data[current].type}s`),
          nextAs: getUrl(`${data[current].type}s`)(data[current].data),
        },
        placeholderType: data[current].type,
        src: getCoverUrl(`${data[current].type}s`)(data[current].data),
        type: data[current].type,
      },
    };

    if (_includes(['catalog', 'album', 'artist', 'playlist'], data[current].type)) {
      curentData.tracksContextId = data[current].id;
      curentData.tracksContextName = playerCtx[data[current].type.toUpperCase()];
    } else {
      curentData.tracksContextName = playerCtx.SEARCH;
    }

    return curentData;
  };

  handleClickOnNextBtn = () => {
    const { data } = this.props;

    return this.setState(({ current }) => ({
      current: current + 1 >= data.length ? 0 : current + 1,
    }));
  };

  handleClickOnPrevBtn = () => {
    const { data } = this.props;

    return this.setState(({ current }) => ({
      current: current - 1 < 0 ? data.length - 1 : current - 1,
    }));
  };

  render() {
    const { data, colors, spacings } = this.props;

    const currentData = this.getCurrentData();

    const currentTracks = _slice(currentData.tracks, 0, 3);

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background || 'transparent',
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
              useCustomColor: colors.useCustomColor,
            },
          }
          : {
            colors: {
              useCustomColor: colors.useCustomColor,
            },
          })}
      >
        <Wrapper spacings={spacings}>
          <WideBackground />

          <Container spacings={spacings}>
            <Header>
              {data.length > 1 && (
              <PrevBtn onClick={this.handleClickOnPrevBtn} />
              )}

              <Cover {...currentData.coverProps} />

              {data.length > 1 && (
              <NextBtn onClick={this.handleClickOnNextBtn} />
              )}
            </Header>
            <Content>
              <Type>{currentData.type}</Type>
              <Title>{currentData.title}</Title>
              <Tracks>
                {currentTracks.map((track, i) => (
                  <StyledMusicItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${track.id}-${i}`}
                    music={track}
                    contextId={currentData.tracksContextId || track.id}
                    contextName={currentData.tracksContextName}
                    contextPosition={currentData.tracksContextId ? i + 1 : null}
                  />
                ))}
              </Tracks>
              <StyledLink {...currentData.coverProps.linkProps}>
                <StyledButton>{currentData.linkText}</StyledButton>
              </StyledLink>
            </Content>
          </Container>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default withTranslation(['common', 'pages'])(TrendingWithTracks);

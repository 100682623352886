// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import {
  getTitle,
  getCoverUrl,
  getUrl,
  getBaseRoute,
  getSearchUrl,
} from '../../../../helpers/entity';
import {
  getPostCoverUrl,
  getPostCoverLinkProps,
  getPostSecondTitle,
  getPostTitle,
} from '../../../../helpers/doc';
import * as pth from '../../../../helpers/proptypes';
import * as playerCtx from '../../../../store/constants/PlayerContexts';

import { WideBackground } from '../singleEntity/styles';
import { Wrapper, Grid, Cover, RectangularItem, RectangularTitle, StyledLink } from './styles';
import { getValueByLocale } from '../../../../helpers/i18n';

// =============================
// Component
// =============================

function Masonry({ colors, data, i18n: { language }, spacings, t }) {
  const { useRectangleVariant } = data;

  const items = data.items.map((item, i) => {
    const key = `${item.id}-${i}`;
    let contextId = '';
    let contextName = '';
    let type = '';
    let placeholderType = '';
    let pretext = '';
    let title = '';
    let src = '';
    let alt = '';
    let linkProps = null;

    if (item.type === 'track') {
      contextId = item.id;
      contextName = playerCtx.SEARCH;
      type = 'track';
      placeholderType = 'track';
      pretext = t('pages:custom_page.track');
      title = getTitle('tracks')(item.data);
      src = getCoverUrl('tracks')(item.data);
      linkProps = getUrl('tracks')(item.data) ? {
        route: getBaseRoute('tracks'),
        nextAs: getUrl('tracks')(item.data),
      } : {};
    }

    if (item.type === 'album') {
      contextId = item.id;
      contextName = playerCtx.ALBUM;
      type = 'album';
      placeholderType = 'album';
      pretext = t('pages:custom_page.album');
      title = getTitle('albums')(item.data);
      src = getCoverUrl('albums')(item.data);
      linkProps = {
        route: getBaseRoute('albums'),
        nextAs: getUrl('albums')(item.data),
      };
    }

    if (item.type === 'artist') {
      contextId = item.id;
      contextName = playerCtx.ARTIST;
      type = 'artist';
      placeholderType = 'artist';
      pretext = t('pages:custom_page.artist');
      title = getTitle('artists')(item.data);
      src = getCoverUrl('artists')(item.data);
      linkProps = {
        route: getBaseRoute('artists'),
        nextAs: getUrl('artists')(item.data),
      };
    }

    if (item.type === 'catalog') {
      contextId = item.id;
      contextName = playerCtx.CATALOG;
      type = 'catalog';
      placeholderType = 'catalog';
      pretext = t('pages:custom_page.catalog');
      title = getTitle('catalogs')(item.data);
      src = getCoverUrl('catalogs')(item.data);
      linkProps = {
        route: getBaseRoute('catalogs'),
        nextAs: getUrl('catalogs')(item.data),
      };
    }

    if (item.type === 'label') {
      type = 'label';
      placeholderType = 'label';
      pretext = t('pages:custom_page.label');
      title = getTitle('labels')(item.data);
      src = getCoverUrl('labels')(item.data);
      linkProps = {
        route: getSearchUrl('labels')(item.data),
      };
    }

    if (item.type === 'playlist') {
      contextId = item.id;
      contextName = playerCtx.PLAYLIST;
      type = 'playlist';
      placeholderType = 'playlist';
      pretext = t('pages:custom_page.playlist');
      title = getTitle('playlists')(item.data);
      src = getCoverUrl('playlists')(item.data);
      linkProps = {
        route: getBaseRoute('playlists'),
        nextAs: getUrl('playlists')(item.data),
      };
    }

    if (item.type === 'publisher') {
      type = 'publisher';
      placeholderType = 'publisher';
      pretext = t('pages:custom_page.publisher');
      title = getTitle('publishers')(item.data);
      src = getCoverUrl('publishers')(item.data);
      linkProps = {
        route: getSearchUrl('publishers')(item.data),
      };
    }

    alt = title;

    if (item.type === 'post') {
      type = 'post';
      placeholderType = 'post';
      pretext = getPostSecondTitle(item, language);
      title = getPostTitle(item, language);
      src = getPostCoverUrl(item);
      alt = getValueByLocale(item.coverImageAlt, language);
      linkProps = getPostCoverLinkProps(item);
    }

    if (useRectangleVariant) {
      return (
        <StyledLink key={key} {...linkProps}>
          <RectangularItem key={item.id} src={src} alt={alt}>
            <RectangularTitle>{title}</RectangularTitle>
          </RectangularItem>
        </StyledLink>
      );
    }

    return (
      <Cover
        key={key}
        contextId={contextId}
        contextName={contextName}
        type={type}
        placeholderType={placeholderType}
        preText={pretext}
        title={title}
        src={src}
        alt={alt}
        linkProps={linkProps}
        vynil={false}
      />
    );
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Grid useRectangleVariant={useRectangleVariant}>{items.map(item => item)}</Grid>
      </Wrapper>
    </WithCustomTheme>
  );
}

Masonry.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    useRectangleVariant: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['pages'])(Masonry);

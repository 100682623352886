// =============================
// Imports
// =============================

import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import { Form, Field } from 'react-final-form';
import { alpha } from '@material-ui/core';

import { withTranslation } from '../../../../config/i18n';

import MewoInput from '../../mewoInput';
import AddressFetcher from '../../../other/address';

import validator from '../../../../validators/modules/contactForm';

import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  Informations,
  StyledForm,
  Title,
  Information,
  EmailIcon,
  LocationIcon,
  PhoneIcon,
  InputsGrid,
  StyledInput,
  StyledTextArea,
  StyledButton,
  SocialsGrid,
  AppleIcon,
  DeezerIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  SpotifyIcon,
  TwitterIcon,
  YoutubeIcon,
  StyledLink,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

class ContactForm extends Component {
  static propTypes = {
    colors: pth.colorsModule.isRequired,
    data: PropTypes.shape({
      contact: PropTypes.shape({
        address: PropTypes.string,
        addressComplement: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
      social: PropTypes.shape({
        twitter: PropTypes.string,
        linkedin: PropTypes.string,
        facebook: PropTypes.string,
        instagram: PropTypes.string,
        spotify: PropTypes.string,
        youtube: PropTypes.string,
        deezer: PropTypes.string,
        applemusic: PropTypes.string,
      }),
      contactInfoBackground: PropTypes.string,
      contactInfoText: PropTypes.string,
      contactInfoPrimary: PropTypes.string,
      outerBackground: PropTypes.string,
    }).isRequired,
    spacings: pth.spacings.isRequired,
    sendContactEmail: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  getSocialLinkIcon = (key) => {
    switch (key) {
      case 'applemusic':
        return <AppleIcon />;
      case 'deezer':
        return <DeezerIcon />;
      case 'facebook':
        return <FacebookIcon />;
      case 'instagram':
        return <InstagramIcon />;
      case 'linkedin':
        return <LinkedinIcon />;
      case 'spotify':
        return <SpotifyIcon />;
      case 'twitter':
        return <TwitterIcon />;
      case 'youtube':
        return <YoutubeIcon />;
      default:
        return key;
    }
  };

  renderSocialLink = () => {
    const { data: { social } } = this.props;

    const socialToRender = [];

    Object.keys(social).forEach((key) => {
      socialToRender.push(
        <StyledLink key={key} href={social[key]}>
          {this.getSocialLinkIcon(key)}
        </StyledLink>,
      );
    });

    return socialToRender;
  };

  handleSendContactEmail = async (data, form) => {
    const { sendContactEmail } = this.props;

    await sendContactEmail(data);
    setTimeout(form.restart);
  };

  render() {
    const {
      colors,
      data: {
        contact,
        contactInfoBackground,
        contactInfoPrimary,
        contactInfoText,
        outerBackground,
      },
      spacings,
      t,
    } = this.props;

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background || 'transparent',
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
              formBackground: colors.background,
              input: {
                background: colors.text && alpha(colors.text, 0.1),
                text: colors.text,
                helpers: {
                  background: colors.text,
                  text: colors.background,
                },
              },
              useCustomColor: colors.useCustomColor,
            },
          }
          : {
            colors: {
              useCustomColor: colors.useCustomColor,
            },
          })}
      >
        <Wrapper spacings={spacings}>
          <WideBackground color={outerBackground || 'transparent'} />
          <Container>
            <Form onSubmit={this.handleSendContactEmail}>
              {({ handleSubmit, submitting }) => (
                <StyledForm
                  onSubmit={handleSubmit}
                  bg={colors.background || 'transparent'}
                >
                  <Title>{t('pages:custom_page.modules.contact_form.contact_us')}</Title>
                  <InputsGrid>
                    <Field
                      name="lastName"
                      component={MewoInput}
                      use={StyledInput}
                      type="text"
                      placeholder={t('common:form.last_name')}
                      validate={validator.lastName}
                    />
                    <Field
                      name="firstName"
                      component={MewoInput}
                      use={StyledInput}
                      type="text"
                      placeholder={t('common:form.first_name')}
                      validate={validator.firstName}
                    />
                    <Field
                      name="email"
                      component={MewoInput}
                      use={StyledInput}
                      type="text"
                      placeholder={t('common:form.email')}
                      validate={validator.email}
                    />
                    <Field
                      name="subject"
                      component={MewoInput}
                      use={StyledInput}
                      type="text"
                      placeholder={t('pages:custom_page.modules.contact_form.subject')}
                      validate={validator.subject}
                    />
                  </InputsGrid>
                  <Field
                    name="message"
                    component={MewoInput}
                    use={StyledTextArea}
                    type="text"
                    validate={validator.message}
                  />
                  <StyledButton disabled={submitting} type="submit">
                    {t('common:form.submit')}
                  </StyledButton>
                </StyledForm>
              )}
            </Form>
            <WithCustomTheme
              customTheme={() => ({
                colors: {
                  background: contactInfoBackground || 'transparent',
                  text: contactInfoText,
                  primary: contactInfoPrimary,
                },
              })}
            >
              <Informations>
                <Title>{t('pages:custom_page.modules.contact_form.informations')}</Title>
                {contact.address && (
                  <Information>
                    <LocationIcon />
                    <AddressFetcher placeId={contact.address}>
                      {address => (!!address || !!contact.addressComplement) && (
                      <Fragment>
                        {address}
                        {!!address && !!contact.addressComplement && <br />}
                        {contact.addressComplement}
                      </Fragment>
                      )}
                    </AddressFetcher>
                  </Information>
                )}
                {contact.phone && (
                  <Information>
                    <PhoneIcon />
                    <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                  </Information>
                )}
                {contact.email && (
                  <Information>
                    <EmailIcon />
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </Information>
                )}

                <SocialsGrid>{this.renderSocialLink()}</SocialsGrid>
              </Informations>
            </WithCustomTheme>
          </Container>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default withTranslation(['common', 'pages'])(ContactForm);

// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Input from '../../inputs/input';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  width: 64rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    width: 100%;
  `};
`;

export const Title = styled.div`
  font-size: 2.2rem;
  margin-bottom: 5rem;
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))``;

export const StyledForm = styled.form`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    align-items: flex-start;
    flex-direction: column;
  `};
`;

export const StyledInput = styled(Input).attrs({
  containerClassName: 'containerClassName',
  variant: 'background',
})`
  & .containerClassName {
    background-color: ${({ theme }) => theme.colors.input.background};
    border: 0;
    color: ${({ theme }) => theme.colors.input.text};
    min-height: 4rem;

    input {
      color: ${({ theme }) => theme.colors.input.text};
    }
  }

  margin-right: 2rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-right: 0;
    margin-bottom: 2rem;
  `};
`;

// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../../other/responsive';

import BenefitsMobile from './mobile';
import BenefitsDesktop from './desktop';

// =============================
// Component
// =============================

class Benefits extends PureComponent {
  static displayName = 'BenefitsResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={BenefitsDesktop}
        MobileComponent={BenefitsMobile}
        props={this.props}
      />
    );
  }
}

export default Benefits;

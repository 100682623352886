// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setVideoOpen } from '../../../../store/actions/ModalsActions';

import MasonryVideo from '../../../presentationals/modules/masonryVideo';

// =============================
// Component
// =============================

export default connect(null, {
  toggleVideoModal: setVideoOpen,
})(MasonryVideo);

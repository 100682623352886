// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { Desktop, Mobile } from '../../../other/responsive';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  StyledButton,
  TitleWrapper,
  Title,
  Description,
  StyledLink,
  ImageContainer,
  Image,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function TitleDescLink({ colors, data, i18n: { language }, spacings }) {
  const title = getValueByLocale(data.title, language);
  const description = getValueByLocale(data.descriptions, language);
  const hasLink = !!data.linkUrl;
  const linkText = getValueByLocale(data.linkText, language);
  const imageSrc = _get(data, 'image.original.url');
  const imageAlt = getValueByLocale(data.imageAlt, language);

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <TitleWrapper>
          {imageSrc && (
            <ImageContainer>
              <Image alt={imageAlt} src={imageSrc} />
            </ImageContainer>
          )}
          <Title>{title}</Title>
          <Desktop>
            {hasLink && data.linkPlacement === 'right' && (
              <StyledLink href={data.linkUrl} placement={data.linkPlacement}>
                <StyledButton>{linkText}</StyledButton>
              </StyledLink>
            )}
          </Desktop>
        </TitleWrapper>

        {/* eslint-disable-next-line react/no-danger */}
        {!!description && <Description dangerouslySetInnerHTML={{ __html: description }} />}

        <Desktop>
          {hasLink && data.linkPlacement === 'bottom' && (
            <StyledLink href={data.linkUrl} placement={data.linkPlacement}>
              <StyledButton>{linkText}</StyledButton>
            </StyledLink>
          )}
        </Desktop>

        <Mobile>
          {hasLink && (
            <StyledLink href={data.linkUrl}>
              <StyledButton>{linkText}</StyledButton>
            </StyledLink>
          )}
        </Mobile>
      </Wrapper>
    </WithCustomTheme>
  );
}

TitleDescLink.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    title: pth.descriptions,
    descriptions: pth.descriptions,
    linkUrl: PropTypes.string,
    linkText: pth.descriptions,
    linkPlacement: PropTypes.oneOf(['bottom', 'right']),
    image: pth.image,
    imageAlt: pth.descriptions,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(TitleDescLink);

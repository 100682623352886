// =============================
// Imports
// =============================

import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { withTranslation } from '../../../../config/i18n';

import { Desktop } from '../../../other/responsive';
import Link from '../../../other/link';

import {
  getPostCoverUrl,
  getPostTitle,
  getPostSecondTitle,
  getPostCoverLinkProps,
  getPostLinkProps,
} from '../../../../helpers/doc';
import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Content,
  Discover,
  Title,
  SecondTitle,
  Description,
  InformationWrapper,
  ContentBackground,
  StyledButton,
  StyledCover,
  StyledScrollIndicator,
} from './styles';

import { getBaseRoute, getUrl, getTitle, getCoverUrl } from '../../../../helpers/entity';
import { getTrackAlbum } from '../../../../helpers/entity/track';

// =============================
// Component
// =============================

class BigCover extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = createRef();
  }

  handleScroll = () => {
    if (typeof _get(this.wrapperRef, 'current.nextSibling.scrollIntoView') === 'function') {
      this.wrapperRef.current.nextSibling.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const {
      colors,
      doc,
      i18n: { language },
      isModuleAlone,
      t,
    } = this.props;

    if (!doc) return null;

    let linkProps;
    let coverProps;
    let title;
    let description;
    let secondTitle;
    let button;

    if (doc.type === 'post') {
      title = getPostTitle(doc);
      description = getValueByLocale(doc.descriptions, language);
      secondTitle = getPostSecondTitle(doc, language);
      linkProps = getPostCoverLinkProps(doc);
      coverProps = {
        alt: getValueByLocale(doc.coverImageAlt, language),
        src: getPostCoverUrl(doc),
        linkProps,
        placeholderType: doc.type,
        type: doc.type,
      };

      button = getPostLinkProps(doc) && (
        <Link inline {...coverProps.linkProps}>
          <StyledButton>{t('pages:custom_page.click_here')}</StyledButton>
        </Link>
      );
    }

    if (doc.type === 'entities') {
      const entityType = doc[0].type;
      const { data, descriptions } = doc[0];

      const nextDescriptions = !_isEmpty(descriptions)
        ? descriptions
        : _get(data, 'descriptions', []);

      title = getTitle(`${entityType}s`)(data);
      secondTitle = t(`pages:custom_page.${entityType}`);
      description = getValueByLocale(nextDescriptions, language);
      linkProps = {
        route: getBaseRoute(`${entityType}s`),
        nextAs: getUrl(`${entityType}s`)(data),
      };
      coverProps = {
        alt: entityType === 'track' ? getTrackAlbum(data).title : title,
        src: getCoverUrl(`${entityType}s`)(data),
        placeholderType: entityType,
        type: entityType,
        linkProps,
      };
      button = (
        <Link inline {...linkProps}>
          <StyledButton>{t('pages:custom_page.click_here')}</StyledButton>
        </Link>
      );
    }

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background,
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
            },
          }
          : {})}
      >
        <Wrapper isModuleAlone={isModuleAlone} ref={this.wrapperRef}>
          <Content>
            <Desktop><ContentBackground useCustomColor={colors.useCustomColor} /></Desktop>
            <StyledCover
              {...coverProps}
              LinkComponent={Link}
              lazyload={false}
            />
            <InformationWrapper>
              <Discover>{t('pages:custom_page.discover')}</Discover>
              {!!secondTitle && <SecondTitle>{secondTitle}</SecondTitle>}
              <Title>{title}</Title>
              {!!description && <Description>{description}</Description>}
              {button}
            </InformationWrapper>
            <Desktop>
              {!isModuleAlone && <StyledScrollIndicator visible onClick={this.handleScroll} />}
            </Desktop>
          </Content>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

BigCover.propTypes = {
  colors: pth.colors.isRequired,
  doc: PropTypes.shape({
    claim: pth.descriptions,
    coverImage: pth.image,
    coverImageAlt: pth.descriptions,
    descriptions: pth.descriptions,
    linkUrl: PropTypes.string,
    title: pth.descriptions,
    type: PropTypes.string,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  /** If True, this module has no siblings. */
  isModuleAlone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['pages'])(BigCover);

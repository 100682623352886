// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../../helpers/modules';

import Button from '../../../button';
import { CoverBase } from '../styles';

// =============================
// Styles
// =============================

/** Note:
 * Those styles are also used in:
 * - modules/post
 * - modules/singleEntity/catalog
 * - modules/singleEntity/label
 * - modules/singleEntity/publisher
 * */

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 80vw;

  ${({ theme }) => theme.mediaAbove.minWidth`
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    width: 86rem;
  `};
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;
  `};
`;

export const Cover = styled(CoverBase).attrs({
  placeholderType: 'artist',
  type: 'artist',
})`
  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 1.6rem;
    width: 60vw;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 30rem;
    width: 30rem;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    height: 40rem;
    width: 40rem;
  `};
`;

export const Informations = styled.div`
  color: ${({ theme }) => theme.colors.text};
  width: 100%;

  ${({ theme }) => theme.mediaAbove.minWidth`
    width: 40rem;
  `};
`;

export const Type = styled.div`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 0.8rem;

  ${({ theme }) => theme.mediaBelow.minWidth`
    text-align: center;
  `};
`;

export const Name = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 2rem;
    margin-bottom: 1.6rem;
    text-align: center;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 2.5rem;
    margin-bottom: 5rem;
  `};
`;

export const Description = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.4;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 1.6rem;
    text-align: center;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 5rem;
  `};
`;

export const DiscoverButton = styled(Button).attrs({
  color: 'primary',
  size: 'medium',
})`
  ${({ theme }) => theme.mediaBelow.minWidth`
    margin: 0 auto;
  `};
`;

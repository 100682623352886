// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import Link from '../../../../other/link';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getTitle, getCoverUrl, getUrl, getBaseRoute } from '../../../../../helpers/entity';
import { getPlaylistTracksCoversUrl } from '../../../../../helpers/entity/playlist';
import * as pth from '../../../../../helpers/proptypes';

import {
  Wrapper,
  Description,
  DiscoverButton,
  CoversWrapper,
  LeftCover,
  MiddleCover,
  RightCover,
  Title,
} from './styles';
import { WideBackground } from '../styles';

// =============================
// Component
// =============================

function Playlist({ doc, locale, spacings, t }) {
  const description = getValueByLocale(doc.descriptions, locale);

  // Get Playlist custom cover.
  const playlistCoverUrl = getCoverUrl('playlists')(doc);

  const tracksCovers = getPlaylistTracksCoversUrl(doc, 'large', 'large');
  const trackNb = tracksCovers.length;

  let leftCoverUrl = '';
  let middleCoverUrl = '';
  let rightCoverUrl = '';

  if (playlistCoverUrl) {
    // With playlist cover
    middleCoverUrl = playlistCoverUrl;

    if (trackNb >= 2) {
      [leftCoverUrl, rightCoverUrl] = tracksCovers;
    }
  } else {
    // Without playlist cover
    if (trackNb < 3 && trackNb > 0) {
      [middleCoverUrl] = tracksCovers;
    }

    if (trackNb >= 3) {
      [middleCoverUrl, leftCoverUrl, rightCoverUrl] = tracksCovers;
    }
  }

  /*
    Use this to test multiple cover styles.
    leftCoverUrl = 'https://picsum.photos/200/200';
    middleCoverUrl = 'https://picsum.photos/200/200';
    rightCoverUrl = 'https://picsum.photos/200/200';
  */

  return (
    <Wrapper spacings={spacings}>
      <WideBackground />
      <CoversWrapper hasMultipleCover={leftCoverUrl && rightCoverUrl}>
        {leftCoverUrl && (
          <LeftCover
            linkProps={{
              route: getBaseRoute('playlists'),
              nextAs: getUrl('playlists')(doc),
            }}
            src={leftCoverUrl}
            alt={getTitle('playlists')(doc)}
          />
        )}
        <MiddleCover
          contextId={doc.id}
          contextName={playerCtx.PLAYLIST}
          linkProps={{
            route: getBaseRoute('playlists'),
            nextAs: getUrl('playlists')(doc),
          }}
          src={middleCoverUrl}
          alt={getTitle('playlists')(doc)}
        />
        {rightCoverUrl && (
          <RightCover
            linkProps={{
              route: getBaseRoute('playlists'),
              nextAs: getUrl('playlists')(doc),
            }}
            src={rightCoverUrl}
            alt={getTitle('playlists')(doc)}
          />
        )}
      </CoversWrapper>

      <Title>{getTitle('playlists')(doc)}</Title>
      {!!description && <Description>{description}</Description>}
      <Link route={getBaseRoute('playlists')} nextAs={getUrl('playlists')(doc)}>
        <DiscoverButton>{t('pages:custom_page.discover_playlist')}</DiscoverButton>
      </Link>
    </Wrapper>
  );
}

Playlist.propTypes = {
  doc: PropTypes.shape({
    covers: PropTypes.arrayOf(pth.image),
    descriptions: pth.descriptions,
    id: PropTypes.string,
    image: pth.image,
    labelName: PropTypes.string,
    tenant: pth.user,
    type: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default Playlist;

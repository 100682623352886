// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import Link from '../../../../other/link';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getTitle, getCoverUrl, getSearchUrl } from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';

// Label uses the same styles as Artist (& post).
import { Wrapper, Container, Informations, Type, Name, Description, DiscoverButton } from '../artist/styles';
import { Cover } from './styles';
import { WideBackground } from '../styles';

// =============================
// Component
// =============================

const LabelEntity = ({ doc, locale, spacings, t }) => {
  const description = getValueByLocale(doc.descriptions, locale);

  return (
    <Wrapper spacings={spacings}>
      <WideBackground />
      <Container>
        <Cover
          contextId={doc.id}
          linkProps={{
            route: getSearchUrl('labels')(doc),
          }}
          src={getCoverUrl('labels')(doc)}
          alt={getTitle('labels')(doc)}
        />
        <Informations>
          <Type>{t('pages:custom_page.label')}</Type>
          <Name>{getTitle('labels')(doc)}</Name>
          {!!description && <Description>{description}</Description>}
          <Link route={getSearchUrl('labels')(doc)}>
            <DiscoverButton>{t('pages:custom_page.discover_label')}</DiscoverButton>
          </Link>
        </Informations>
      </Container>
    </Wrapper>
  );
};

LabelEntity.propTypes = {
  doc: PropTypes.shape({
    descriptions: pth.descriptions,
    id: PropTypes.string,
    image: pth.image,
    labelName: PropTypes.string,
    tenant: pth.user,
    type: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default LabelEntity;

// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';
import link from '../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  align-items: center;
  border: 0.1rem solid ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  line-height: 2.2rem;
  text-align: center;

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 1.2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 2.4rem;
  `};
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 4rem;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `};
`;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import MusicItemMini from '../../../containers/musicitemmini';
import { Desktop, Mobile } from '../../../other/responsive';

import * as playerCtx from '../../../../store/constants/PlayerContexts';

import * as pth from '../../../../helpers/proptypes';

import { WideBackground } from '../singleEntity/styles';
import { Wrapper, Grid, StyledMusicItem } from './styles';

// =============================
// Component
// =============================

function Tracks({ colors, data, spacings }) {
  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Grid>
          <Desktop>
            {data.map((track, i) => (
              <MusicItemMini
                // eslint-disable-next-line react/no-array-index-key
                key={`${track.id}-${i}`}
                track={track}
                contextName={playerCtx.SEARCH}
              />
            ))}
          </Desktop>
          <Mobile>
            {data.map((track, i) => (
              <StyledMusicItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${track.id}-${i}`}
                contextName={playerCtx.SEARCH}
                music={track}
              />
            ))}
          </Mobile>
        </Grid>
      </Wrapper>
    </WithCustomTheme>
  );
}

Tracks.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(pth.smalltrack).isRequired,
  spacings: pth.spacings.isRequired,
};

export default Tracks;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import AddressFetcher from '../../../other/address';

import * as pth from '../../../../helpers/proptypes';

import {
  Container,
  Content,
  ContactInfo,
  Information,
  ImageWrapper,
  Address,
  Maps,
  VisualContainer,
} from './styles';
import { WideBackground } from '../singleEntity/styles';
import { getValueByLocale } from '../../../../helpers/i18n';

// =============================
// Component
// =============================

function Contact({ colors, doc, id, i18n: { language }, spacings }) {
  const renderAddress = contact => (
    <Address key={contact.id || contact.name}>
      <Information>{contact.name}</Information>
      <AddressFetcher placeId={contact.address}>
        {address => (!!address || !!contact.addressComplement) && (
          <Information>
            {address}
            {!!address && !!contact.addressComplement && <br />}
            {contact.addressComplement}
          </Information>
        )}
      </AddressFetcher>
      {!!contact.email && <Information>{contact.email}</Information>}
      {!!contact.phone && <Information>{contact.phone}</Information>}
    </Address>
  );

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Container spacings={spacings}>
        <WideBackground />
        <Content>
          {!!doc.contacts.length && (
            <ContactInfo>
                {doc.contacts.map(contact => renderAddress(contact))}
            </ContactInfo>
          )}
          <VisualContainer>
            {_get(doc, 'contactImage.original.url') ? (
              <ImageWrapper
                src={_get(doc, 'contactImage.original.url')}
                alt={getValueByLocale(doc.contactImageAlt, language)}
              />
            ) : (doc.googlePlaceId && (
              <Maps id={id} placeId={doc.googlePlaceId} googleApiKey={process.env.GOOGLE_API_KEY} />
            ))}
          </VisualContainer>
        </Content>
      </Container>
    </WithCustomTheme>
  );
}

Contact.propTypes = {
  colors: pth.colors.isRequired,
  id: PropTypes.string.isRequired,
  doc: PropTypes.shape({
    contactImage: pth.image,
    contactImageAlt: pth.descriptions,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        address: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      }),
    ),
    googlePlaceId: PropTypes.string,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation([])(Contact);

// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import Button from '../../../button';

import { CoverBase } from '../styles';
import { setSpacings } from '../../../../../helpers/modules';

// =============================
// Styles
// =============================

export const CoversWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  transition: transform ${({ theme }) => theme.transition.medium}
    ${({ theme }) => theme.transitionTiming.easeInOut};
  z-index: 1;

  ${({ hasMultipleCover, theme }) => theme.mediaBelow.minWidth`
    width: 100%;

    ${hasMultipleCover ? css`
      margin: 0 auto 5rem;
    ` : css`
      margin: 0 auto 2rem;
    `};
  `};

  ${({ hasMultipleCover, theme }) => theme.mediaAbove.minWidth`
    height: 27rem;
    width: 27rem;

    ${hasMultipleCover ? css`
      margin: 0 auto 5rem;
    ` : css`
      margin: 0 auto 2rem;
    `};
  `};
`;

export const Cover = styled(CoverBase).attrs({
  noTilt: true,
  noTranslateY: true,
  noScale: true,
})`
  transition: transform ${({ theme }) => theme.transition.medium}
    ${({ theme }) => theme.transitionTiming.easeInOut};
`;

export const MiddleCover = styled(Cover).attrs({
  type: 'playlist',
  placeholderType: 'playlist',
})`
  ${({ theme }) => theme.mediaBelow.minWidth`
    width: 40%;
  `};
`;

export const LeftCover = styled(Cover).attrs({
  type: 'track',
  placeholderType: 'track',
})`
  position: absolute;
  transform: rotate(-5deg);
  transform-origin: center center;
  z-index: -1;

  ${({ theme }) => theme.mediaBelow.minWidth`
    left: 5%;
    top: 2rem;
    width: 40%;
  `};
  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 25rem;
    left: -18rem;
    top: 5rem;
    width: 25rem;
  `};
`;

export const RightCover = styled(Cover).attrs({
  type: 'track',
  placeholderType: 'track',
})`
  position: absolute;
  transform: rotate(5deg);
  transform-origin: center center;
  z-index: -1;

  ${({ theme }) => theme.mediaBelow.minWidth`
    right: 5%;
    top: 2rem;
    width: 40%;
  `};
  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 25rem;
    right: -18rem;
    top: 5rem;
    width: 25rem;
  `};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 2.5rem;
    margin-bottom: 3rem;
    width: 42rem;
  `};
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.4;
  text-align: center;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 2rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 3rem;
    width: 50rem;
  `};
`;

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;

  &:hover ${LeftCover} {
    transform: rotate(-7deg) translate3d(-1rem, 0, 0);
  }
  &:hover ${MiddleCover} {
    transform: translate3d(0, -1rem, 0);
  }
  &:hover ${RightCover} {
    transform: rotate(7deg) translate3d(1rem, 0, 0);
  }
`;

export const DiscoverButton = styled(Button).attrs({
  color: 'primary',
  size: 'medium',
})``;

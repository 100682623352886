// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Link from '../../../other/link';
import { textEditorContentStyles } from '../styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    ${({ imageHorizontalPlacement }) => {
    switch (imageHorizontalPlacement) {
      case 'left': return css`
          flex-direction: row;
        `;

      default:
      case 'right': return css`
          flex-direction: row-reverse;
        `;
    }
  }}

  ${({ imageVerticalPlacement }) => {
    switch (imageVerticalPlacement) {
      case 'bottom': return css`
        align-items: flex-end;
      `;
      case 'center': return css`
        align-items: center;
      `;
      default:
      case 'top': return css`
        align-items: flex-start;
      `;
    }
  }}
  `};
`;

export const StyledImage = styled.img.attrs({
  loading: 'lazy',
})`
  flex-shrink: 0;
  height: auto;
  position: relative;
  width: ${({ width }) => width}%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 1.2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    ${({ imageHorizontalPlacement }) => (imageHorizontalPlacement === 'left'
    ? css`
      margin-right: 5rem;
    `
    : css`
      margin-left: 5rem;
    `)};
  `};
`;

export const Content = styled.div``;

export const Claim = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 2.4rem;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 3rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  `};
`;

export const Description = styled.div`
  ${textEditorContentStyles};

  ${({ theme }) => theme.mediaBelow.minWidth`
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  `};
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'medium',
}))``;

// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  display: grid;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 8rem;
  `};
`;

export const Benefit = styled.div``;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 2.4rem;

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 2.4rem;
    line-height: 3rem;
  `};

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `};
`;

// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../../other/responsive';

import SliderVideoMobile from './mobile';
import SliderVideoDesktop from './desktop';

// =============================
// Component
// =============================

class SliderVideo extends PureComponent {
  static displayName = 'SliderVideoResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={SliderVideoDesktop}
        MobileComponent={SliderVideoMobile}
        props={this.props}
      />
    );
  }
}

export default SliderVideo;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { Desktop, Mobile } from '../../../other/responsive';

import * as pth from '../../../../helpers/proptypes';

import { Wrapper, StyledImage } from './styles';
import { WideBackground } from '../singleEntity/styles';
import { getValueByLocale } from '../../../../helpers/i18n';

// =============================
// Component
// =============================

function Image({ colors, data, i18n: { language }, spacings }) {
  const imageSrc = _get(data, 'image.original.url');
  const imageSrcMobile = _get(data, 'imageMobile.original.url');
  const imageAlt = getValueByLocale(data.imageAlt, language);

  const {
    fullWidth,
    imageWidth,
    imageWidthMobile,
  } = data;

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Desktop>
          <StyledImage
            alt={imageAlt}
            fullWidth={fullWidth}
            src={imageSrc}
            width={imageWidth}
          />
        </Desktop>
        <Mobile>
          <StyledImage
            alt={imageAlt}
            fullWidth={fullWidth}
            src={imageSrcMobile || imageSrc}
            width={imageWidthMobile || imageWidth}
          />
        </Mobile>
      </Wrapper>
    </WithCustomTheme>
  );
}

Image.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    fullWidth: PropTypes.bool,
    image: pth.image,
    imageAlt: pth.descriptions,
    imageWidth: PropTypes.number,
    imageWidthMobile: PropTypes.number,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation([])(Image);

// =============================
// Imports
// =============================

import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';
import { AnimatePresence } from 'framer-motion';

import { withTranslation } from '../../../../../config/i18n';

import { getValueByLocale } from '../../../../../helpers/i18n';
import * as pth from '../../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  BackgroundImageContainer,
  BackgroundImage,
  Content,
  Title,
  CompanyNameType,
  CompanyName,
  Type,
  Controls,
  PlayBtn,
  ControlsInfo,
  ControlsTitle,
  ControlsSubTitle,
  ThumbnailWrapper,
  ThumbnailImageContainer,
  ThumbnailImage,
  Thumbnail,
  ThumbnailTitle,
} from './styles';
import { WideBackground } from '../../singleEntity/styles';

// =============================
// Component
// =============================

class SliderVideo extends Component {
  static displayName = 'SliderVideoDesktop';

  static propTypes = {
    colors: pth.colorsModule.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: pth.descriptions,
        videoLink: PropTypes.string,
        subTitle: pth.descriptions,
        type: pth.descriptions,
        companyName: PropTypes.string,
        descriptions: pth.descriptions,
        thumbnail: pth.image,
        thumbnailAlt: pth.descriptions,
        trackData: pth.smalltrack,
      }),
    ).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    spacings: pth.spacings.isRequired,
    toggleVideoModal: PropTypes.func.isRequired,
  };

  state = {
    current: 0,
    lastClick: 0,
  };

  constructor(props) {
    super(props);

    this.contentRef = createRef();
  }

  componentDidMount() {
    this.interval = setInterval(this.handleAutoScroll, 5000);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  getCurrentThumbnailsData = () => {
    const { data, i18n: { language } } = this.props;

    const { current } = this.state;

    return {
      id: data[current].id,
      title: getValueByLocale(data[current].title, language),
      videoLink: data[current].videoLink,
      description: getValueByLocale(data[current].descriptions, language),
      subTitle: getValueByLocale(data[current].subTitle, language),
      type: getValueByLocale(data[current].type, language),
      companyName: _get(data[current], 'companyName'),
      trackData: data[current].trackData,
      image: _get(data[current], 'thumbnail.original.url'),
      imageAlt: getValueByLocale(data[current].thumbnailAlt, language),
    };
  };

  getThumbnailsData = () => {
    const { data, i18n: { language } } = this.props;
    const { current } = this.state;

    return data.map((item, key) => ({
      id: item.id,
      title: getValueByLocale(item.title, language),
      companyName: _get(item.title, 'companyName', undefined),
      subTitle: getValueByLocale(item.subTitle, language),
      image: _get(item, 'thumbnail.original.url', undefined),
      imageAlt: getValueByLocale(item.thumbnailAlt, language),
      isCurrent: key === current,
    }));
  };

  handleAutoScroll = () => {
    const { data } = this.props;
    const { lastClick } = this.state;

    // We autoscroll if user didn't click on a thumbnail for 5seconds.
    if ((+new Date() - lastClick) / 1000 > 5) {
      return this.setState(state => ({
        current: state.current + 1 >= data.length ? 0 : state.current + 1,
        lastClick: +new Date(),
      }));
    }

    return false;
  };

  handleClickOnThumbnail = index => this.setState({
    current: index,
    lastClick: +new Date(),
  });

  renderControls = () => {
    const { toggleVideoModal } = this.props;
    const { title, videoLink, subTitle, description, trackData } = this.getCurrentThumbnailsData();

    return (
      <Controls>
        <PlayBtn
          onClick={() => toggleVideoModal(true, {
            trackData,
            videoData: {
              title,
              description,
              url: videoLink,
            },
          })}
        />
        <ControlsInfo>
          <ControlsTitle>{title}</ControlsTitle>
          <ControlsSubTitle>{subTitle}</ControlsSubTitle>
        </ControlsInfo>
      </Controls>
    );
  };

  renderThumbnails = () => {
    const thumbnails = this.getThumbnailsData();

    if (thumbnails.length <= 1) return null;

    return (
      <ThumbnailWrapper>
        {thumbnails.map((thumbnail, index) => (
          <Thumbnail
            key={thumbnail.id}
            onClick={() => this.handleClickOnThumbnail(index)}
          >
            <ThumbnailImageContainer>
              <ThumbnailTitle>{thumbnail.title}</ThumbnailTitle>
              <ThumbnailImage
                isCurrent={thumbnail.isCurrent}
                src={thumbnail.image}
                alt={thumbnail.imageAlt}
              />
            </ThumbnailImageContainer>
          </Thumbnail>
        ))}
      </ThumbnailWrapper>
    );
  };

  render() {
    const { colors, spacings } = this.props;

    const currentThumbnail = this.getCurrentThumbnailsData();

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background || 'transparent',
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
              useCustomColor: colors.useCustomColor,
            },
          }
          : {
            colors: {
              useCustomColor: colors.useCustomColor,
            },
          })}
      >
        <Wrapper spacings={spacings}>
          <WideBackground />
          <Container>
            <BackgroundImageContainer>
              <AnimatePresence>
                <BackgroundImage
                  key={currentThumbnail.id}
                  src={currentThumbnail.image}
                  alt={currentThumbnail.imageAlt}
                />
              </AnimatePresence>
            </BackgroundImageContainer>
            <Content ref={this.contentRef}>
              <CompanyNameType>
                <CompanyName>{currentThumbnail.companyName}</CompanyName>
                <Type>{currentThumbnail.type}</Type>
              </CompanyNameType>
              <Title>{currentThumbnail.title}</Title>
              {this.renderControls()}
              {this.renderThumbnails()}
            </Content>
          </Container>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default withTranslation(['pages'])(SliderVideo);

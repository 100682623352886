// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _find from 'lodash/find';

import {
  setSearchInDescription as setSearchInDescriptionBase,
  requestMaiaAudioSearch as requestMaiaAudioSearchBase,
  setFullSizeCoverModuleData,
  clearFullSizeCoverModuleData,
} from '../../../../store/actions/SearchActions';
import { setPreSearchOpen } from '../../../../store/actions/ModalsActions';

import FullSizeCoverSearchBar from '../../../presentationals/modules/fullSizeCover/searchbar';

// =============================
// Component
// ============================

function mapStateToProps({ modals, search, config }, { moduleData }) {
  return {
    config: config.data,
    isPreSearchOpen: modals.isPreSearchOpen,
    searchInDescription: search.searchInDescription,
    isSendingMaiaAudioSignal: search.tracksSearch.isSendingMaiaAudioSignal,
    isVisible: _find(
      search.fullSizeCoverModule, item => item.id === moduleData.id,
    )?.isVisible || false,
  };
}

export default connect(mapStateToProps, {
  togglePreSearchModal: setPreSearchOpen,
  requestMaiaAudioSearch: requestMaiaAudioSearchBase,
  setSearchInDescription: setSearchInDescriptionBase,
  setFullSizeCoverModuleData,
  clearFullSizeCoverModuleData,
})(FullSizeCoverSearchBar);

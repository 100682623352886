// =============================
// Imports
// =============================

import React, { createRef, Component } from 'react';
import Router from 'next/router';
import ReactVisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';
import isURL from 'validator/lib/isURL';

import { withTranslation } from '../../../../config/i18n';

import SearchBar from '../../../containers/modules/fullSizeCoverSearchBar';
import ScrollIndicator from '../../scrollIndicator';
import { Desktop, Mobile } from '../../../other/responsive';

import { getEntitySearchUrl } from '../../../../helpers/entity';
import { getValueByLocale } from '../../../../helpers/i18n';
import { composeQuery } from '../../../../helpers/search';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  BackgroundImage,
  Title,
  TitleSearchBarWrapper,
  VideoLink,
  VideoLinkWrapper,
  ScrollIndicatorWrapper,
  BackgroundVideoWrapper,
  BackgroundVideo,
  VideoRatioKeeper,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

class FullSizeCover extends Component {
  static propTypes = {
    clearFullSizeCoverModuleData: PropTypes.func.isRequired,
    colors: pth.colorsModule.isRequired,
    data: PropTypes.shape({
      displayVideoLink: PropTypes.bool,
      displaySearchBar: PropTypes.bool,
      displayScrollIndicator: PropTypes.bool,
      id: PropTypes.string,
      image: pth.image,
      imageAlt: pth.descriptions,
      videoLink: PropTypes.string,
      videoRatioX: PropTypes.number,
      videoRatioY: PropTypes.number,
      title: pth.descriptions.isRequired,
    }).isRequired,
    getPreSearchData: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Initial search filters (even those non available anymore due to filtering). */
    initialFacets: pth.facets.isRequired,
    isPreSearchFetchedOnce: PropTypes.bool.isRequired,
    isPreSearchOpen: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    preSearchDatas: pth.preSearchDatas.isRequired,
    privacyCookies: PropTypes.shape({
      mandatory: PropTypes.bool.isRequired,
      analytics: PropTypes.bool.isRequired,
      media: PropTypes.bool.isRequired,
    }).isRequired,
    requestMaiaTextSearch: PropTypes.func.isRequired,
    resetPreSearchData: PropTypes.func.isRequired,
    router: PropTypes.shape({
      route: PropTypes.string,
    }).isRequired,
    searchQuery: pth.searchquery.isRequired,
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    spacings: pth.spacings.isRequired,
  };

  constructor(props) {
    super(props);

    this.titleSearchRef = createRef();
    this.wrapperRef = createRef();
  }

  componentWillUnmount() {
    const { clearFullSizeCoverModuleData, data } = this.props;

    clearFullSizeCoverModuleData(data.id);
  }

  handleFocus = () => {
    const { getPreSearchData, isPreSearchFetchedOnce, searchValue } = this.props;

    if (searchValue && !isPreSearchFetchedOnce) {
      getPreSearchData(searchValue);
    }
  };

  handleSearchOnChange = (value) => {
    const { getPreSearchData, resetPreSearchData, searchValue, setSearchValue } = this.props;

    const valueIsExternal = isURL(value);

    if (value && valueIsExternal) {
      // No need to fetch presearch data
      setSearchValue(value);
    } else if (value && value !== searchValue) {
      // If the value has changed and it is truthy
      setSearchValue(value);
      getPreSearchData(value);
    } else if (searchValue) {
      resetPreSearchData();
    }
  };

  handleSearchOnSubmit = () => {
    const {
      initialFacets,
      preSearchDatas,
      requestMaiaTextSearch,
      router,
      searchQuery,
      searchValue,
    } = this.props;

    // NOTE: When value is null and route is not search redirect to all tracks
    if (!searchValue && router.route !== '/search') {
      Router.push('/search');
    }

    // Check presearch column results
    const presearchColWithResults = [];

    Object.keys(preSearchDatas).forEach((key) => {
      if (_get(preSearchDatas, `${key}.total.value`) > 0) {
        presearchColWithResults.push(key);
      }
    });

    // If there is only one column left
    if (presearchColWithResults.length === 1) {
      const key = presearchColWithResults[0];

      if (key !== 'tags') {
        // For all columns except tags, go to "see list" page
        Router.push(getEntitySearchUrl(key)(searchValue));
      } else if (_get(preSearchDatas, `${key}.total.value`) === 1) {
        // For tags, add tag to query
        const tagId = _get(preSearchDatas, `${key}.data.0.id`);
        const tagAvailable = Object.keys(_get(initialFacets, 'tags', {})).indexOf(tagId) !== -1;

        if (tagAvailable) {
          const query = composeQuery(searchQuery, { tags: [tagId] });

          Router.push(`/search?${query}`);
        }
      }
    }

    if (isURL(searchValue)) {
      requestMaiaTextSearch(searchValue, searchValue);
    }
  };

  handleSearchBarClick = () => {
    if (typeof _get(this.wrapperRef, 'current.scrollIntoView') === 'function') {
      return this.wrapperRef.current.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
      });
    }

    return null;
  };

  handleScrollIndicatorClick = () => {
    const { spacings } = this.props;

    // We get scroll container.
    const scrollContainer = _get(this.wrapperRef, 'current.parentElement.parentElement');

    if (scrollContainer) {
      // We check if FullSizeCover has a sibling.
      if (scrollContainer && _get(this.wrapperRef, 'current.nextSibling.offsetTop', null)) {
        // If FullSizeCover has no margin-bottom
        // Its usally a sign that the next module is either non-existent or has a 100vh height.
        // So, we don't add 50px extra scroll.
        const marginBottom = spacings.margin.bottom;

        const scrollValue = this.wrapperRef.current.nextSibling.offsetTop
          - (marginBottom === 0 ? 0 : marginBottom + 50);

        return scrollContainer.scrollTo({
          behavior: 'smooth',
          top: scrollValue,
        });
      }
    }

    return null;
  };

  render() {
    const {
      colors,
      data,
      i18n: { language },
      isPreSearchOpen,
      isVisible,
      privacyCookies,
      searchValue,
      spacings,
    } = this.props;

    const title = getValueByLocale(data.title, language);
    const imageSrc = _get(data, 'image.original.url');
    const imageSrcMobile = _get(data, 'imageMobile.original.url');
    const imageAlt = getValueByLocale(data.imageAlt, language);

    const {
      displayVideoLink,
      displayScrollIndicator,
      displaySearchBar,
      videoLink,
      videoRatioX,
      videoRatioY,
    } = data;

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background || 'transparent',
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
              useCustomColor: colors.useCustomColor,
            },
          }
          : {
            colors: {
              useCustomColor: colors.useCustomColor,
            },
          })}
      >
        <ReactVisibilitySensor minTopValue={0} partialVisibility>
          {({ isVisible: wrapperIsVisible }) => (
            <Wrapper spacings={spacings} ref={this.wrapperRef}>
              <WideBackground />
              <Container>
                {privacyCookies.media && videoLink ? (
                  <BackgroundVideoWrapper playing={wrapperIsVisible}>
                    <VideoRatioKeeper ratioX={videoRatioX} ratioY={videoRatioY}>
                      <BackgroundVideo url={videoLink} />
                    </VideoRatioKeeper>
                  </BackgroundVideoWrapper>
                ) : (
                  <React.Fragment>
                    <Desktop>
                      <BackgroundImage alt={imageAlt} src={imageSrc} />
                    </Desktop>
                    <Mobile>
                      <BackgroundImage alt={imageAlt} src={imageSrcMobile} />
                    </Mobile>
                  </React.Fragment>
                )}
                <TitleSearchBarWrapper
                  ref={this.titleSearchRef}
                  moveUp={isPreSearchOpen && isVisible}
                >
                  {title && <Title>{title}</Title>}
                  <Desktop>
                    <SearchBar
                      value={searchValue}
                      showSearchBar={displaySearchBar}
                      moduleData={data}
                      moduleColors={colors}
                      onClick={this.handleSearchBarClick}
                      onFocus={this.handleFocus}
                      onChange={this.handleSearchOnChange}
                      onSubmit={this.handleSearchOnSubmit}
                    />
                  </Desktop>
                </TitleSearchBarWrapper>

                <Desktop>
                  {displayVideoLink && videoLink && (
                    <VideoLinkWrapper>
                      <VideoLink href={videoLink}>{videoLink}</VideoLink>
                    </VideoLinkWrapper>
                  )}
                  {displayScrollIndicator && (
                    <ScrollIndicatorWrapper>
                      <ScrollIndicator onClick={this.handleScrollIndicatorClick} visible />
                    </ScrollIndicatorWrapper>
                  )}
                </Desktop>
              </Container>
            </Wrapper>
          )}
        </ReactVisibilitySensor>
      </WithCustomTheme>
    );
  }
}

export default withTranslation([])(FullSizeCover);

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import Link from '../../../../other/link';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getTitle, getCoverUrl, getBaseRoute, getUrl } from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';

// Catalog uses the same styles as Artist (& post).
import { Wrapper, Container, Informations, Type, Name, Description, DiscoverButton } from '../artist/styles';
import { Cover } from './styles';
import { WideBackground } from '../styles';

// =============================
// Component
// =============================

const CatalogEntity = ({ doc, locale, spacings, t }) => {
  const description = getValueByLocale(doc.descriptions, locale);

  return (
    <Wrapper spacings={spacings}>
      <WideBackground />
      <Container>
        <Cover
          contextId={doc.id}
          contextName={playerCtx.CATALOG}
          linkProps={{
            route: getBaseRoute('catalogs'),
            nextAs: getUrl('catalogs')(doc),
          }}
          src={getCoverUrl('catalogs')(doc)}
          alt={getTitle('catalogs')(doc)}
        />
        <Informations>
          <Type>{t('pages:custom_page.catalog')}</Type>
          <Name>{getTitle('catalogs')(doc)}</Name>
          {!!description && <Description>{description}</Description>}
          <Link route={getBaseRoute('catalogs')} nextAs={getUrl('catalogs')(doc)}>
            <DiscoverButton>{t('pages:custom_page.discover_catalog')}</DiscoverButton>
          </Link>
        </Informations>
      </Container>
    </Wrapper>
  );
};

CatalogEntity.propTypes = {
  doc: PropTypes.shape({
    descriptions: pth.descriptions,
    id: PropTypes.string,
    image: pth.image,
    name: PropTypes.string,
    tenant: pth.user,
    type: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default CatalogEntity;

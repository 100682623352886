// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getTitle, getCoverUrl, getBaseRoute, getUrl } from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';

import { Wrapper, Cover } from './styles';
import { WideBackground } from '../styles';

// =============================
// Component
// ============================

const AlbumEntity = ({ doc, locale, spacings, t }) => (
  <Wrapper spacings={spacings}>
    <WideBackground />
    <Cover
      contextId={doc.id}
      contextName={playerCtx.ALBUM}
      linkProps={{
        route: getBaseRoute('albums'),
        nextAs: getUrl('albums')(doc),
      }}
      description={getValueByLocale(doc.descriptions, locale)}
      key={doc.id}
      preText={t('pages:custom_page.album')}
      src={getCoverUrl('albums')(doc)}
      title={getTitle('albums')(doc)}
      alt={getTitle('albums')(doc)}
      vynil
    />
  </Wrapper>
);

AlbumEntity.propTypes = {
  doc: PropTypes.shape({
    aliases: PropTypes.arrayOf(PropTypes.string),
    descriptions: pth.descriptions,
    fullName: PropTypes.string,
    id: PropTypes.string,
    image: pth.image,
    tenant: pth.user,
    type: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default AlbumEntity;

// =============================
// Imports
// =============================

import styled, { keyframes } from 'styled-components';
import { GoogleMaps } from '@mewo/components';

import { setSpacings } from '../../../../helpers/modules';

import {
  wrapperWidth,
  wrapperWidthSxga,
  wrapperWidthWxgaPlus,
} from '../../../layouts/common.variables';
import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Notes
// =============================

// InformationBox should animate 200ms after ImageWrapper

// =============================
// Styles
// =============================

export const Container = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  justify-content: flex-end;
  /** overflow: hidden; */
  position: relative;
  /** Make element full screen width regardless of parent width. */
  margin-left: calc(-50vw + 50%);
  width: 100vw;

  ${({ theme }) => theme.mediaAbove.minWidth`
    min-height: 70vh;

    &:first-child {
      height: 100vh;
    }
  `};
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 100%;
    width: calc(${wrapperWidth} + ((100vw - ${wrapperWidth}) / 2));
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    width: calc(${wrapperWidthSxga} + ((100vw - ${wrapperWidthSxga}) / 2));
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    width: calc(${wrapperWidthWxgaPlus} + ((100vw - ${wrapperWidthWxgaPlus}) / 2));
  `};
`;

export const ContactInfo = styled.div`
  display: grid;
  flex-shrink: 0;
  margin-right: auto;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-column-gap: 2rem;
    grid-row-gap: 3.2rem;
    grid-template-columns: repeat(2, minmax(0, 17rem));
    padding: ${paddingX};
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-column-gap: 10rem;
    grid-row-gap: 3.2rem;
    grid-template-columns: repeat(2, minmax(0, 17rem));
    width: 50%;
  `};
`;

const animationInformationBox = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-4rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const Information = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

const animationImageWrapper = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(10rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const VisualContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaAbove.minWidth`
    width: 40%;
  `};
`;

export const ImageWrapper = styled.span.attrs(({ alt }) => ({
  'aria-label': alt,
  role: 'img',
}))`
  background-image: url('${({ src }) => src}');
  background-size: cover;
  background-position: center;
  display: block;
  overflow: hidden;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    height: 20rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    animation: ${animationImageWrapper} 500ms 400ms ${theme.transitionTiming.easeOut} 1 forwards;
    box-shadow: 0 25px 70px 0 ${theme.colors.boxShadow};
    /** Image ratio is 10:11. */
    padding-top: 110%;

    /* Before animation */
    opacity: 0;
    transform: translate3d(10rem, 0, 0);
  `};
`;

export const Address = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  line-height: 1.8rem;
  width: 100%;

  ${({ theme }) => theme.mediaAbove.minWidth`
    animation: ${animationInformationBox} 500ms 700ms ${theme.transitionTiming.easeOut}
    1 forwards;

    /* Before animation */
    opacity: 0;
    transform: translate3d(-4rem, 0, 0);
  `};
`;

export const Maps = styled(GoogleMaps)`
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    height: 20rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    /** Image ratio is 10:11. */
    padding-top: 110%;
  `};
`;

// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Link from '../../../other/link';

import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

/**
 * The top outerBackground must take 50% of the Container size.
 * The :after is needed to take in account any padding-top value that would also need to be in
 * the outerBackgroundTop color.
 */
export const TopOuterBackground = styled.div`
  height: 100%;
  left: 50%;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
  top: 0;
  width: 100%;
  z-index: -1;

  &:before {
    background-color: ${({ outerBackgroundTop }) => outerBackgroundTop || 'transparent'};
    content: '';
    display: block;
    height: 50%;
    left: 0;
    margin-left: calc(-50vw + 50%);
    position: absolute;
    top: 0;
    width: 100vw;
  }

  &:after {
    background-color: ${({ outerBackgroundTop }) => outerBackgroundTop || 'transparent'};
    content: '';
    display: block;
    height: ${({ spacings }) => `${spacings.padding.top / 10}rem`};
    left: 0;
    margin-left: calc(-50vw + 50%);
    position: absolute;
    top: ${({ spacings }) => `-${spacings.padding.top / 10}rem`};;
    width: 100vw;
  }
`;

export const Container = styled.div`
  background-color: ${({ outerBackgroundTop }) => outerBackgroundTop || 'transparent'};
  position: relative;
  /** Needed for outerBackgrounds to be above WideBackground. */
  z-index: 0;

  &:after {
    background-color: ${({ outerBackgroundBottom }) => outerBackgroundBottom || 'transparent'};
    bottom: ${({ spacings }) => `calc(0rem - ${spacings.padding.bottom / 10}rem)`};
    content: '';
    display: block;
    height: ${({ spacings }) => `calc(50% + ${spacings.padding.bottom / 10}rem)`};
    left: 0;
    margin-left: calc(-50vw + 50%);
    position: absolute;
    width: 100vw;
    z-index: -2;
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    align-items: stretch;
    justify-content: flex-start;
  `};
`;

export const Image = styled.span.attrs(({ alt }) => ({
  'aria-label': alt,
  role: 'img',
}))`
  flex-shrink: 0;

  &:before {
    ${({ src }) => src && css`
      background-image: url("${src}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `};
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }

  ${({ theme }) => theme.mediaBelow.minWidth`
    height: calc((100vw - (${paddingX} * 2)) / 2);
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    min-height: 35rem;
    width: 50%;
  `};
`;

export const Texts = styled.div`
  padding: 4rem;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    padding: ${paddingX};
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding: 4rem;
  `};
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 2.5rem;
    line-height: 3.1rem;
    margin-bottom: 4rem;
  `};
`;

export const Label = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const LabelImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 3rem;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 1.6rem;
  position: relative;
  width: 3rem;
`;

export const LabelImage = styled.img.attrs({
  loading: 'lazy',
})`
  max-height: 100%;
  max-width: 100%;
`;

export const LabelDesc = styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.4rem;
    line-height: 1.8rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.5rem;
    line-height: 2rem;
  `};
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  margin-top: 1.6rem;
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'medium',
}))``;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import Link from '../../../../other/link';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getTitle, getCoverUrl, getSearchUrl } from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';

// Publisher uses the same styles as Artist (& post).
import { Wrapper, Container, Informations, Type, Name, Description, DiscoverButton } from '../artist/styles';
import { Cover } from './styles';
import { WideBackground } from '../styles';

// =============================
// Component
// =============================

const PublisherEntity = ({ doc, locale, spacings, t }) => {
  const description = getValueByLocale(doc.descriptions, locale);

  return (
    <Wrapper spacings={spacings}>
      <WideBackground />
      <Container>
        <Cover
          contextId={doc.id}
          linkProps={{
            route: getSearchUrl('publishers')(doc),
          }}
          src={getCoverUrl('publishers')(doc)}
          alt={getTitle('publishers')(doc)}
        />
        <Informations>
          <Type>{t('pages:custom_page.publisher')}</Type>
          <Name>{getTitle('publishers')(doc)}</Name>
          {!!description && <Description>{description}</Description>}
          <Link route={getSearchUrl('publishers')(doc)}>
            <DiscoverButton>{t('pages:custom_page.discover_publisher')}</DiscoverButton>
          </Link>
        </Informations>
      </Container>
    </Wrapper>
  );
};

PublisherEntity.propTypes = {
  doc: PropTypes.shape({
    descriptions: pth.descriptions,
    id: PropTypes.string,
    image: pth.image,
    name: PropTypes.string,
    tenant: pth.user,
    type: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default PublisherEntity;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  Partner,
  PartnerImgContainer,
  PartnerImg,
  PartnerDesc,
  PartnerContent,
  StyledLink,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function Partners({ colors, data, i18n: { language }, spacings }) {
  const partners = data.map((partner) => {
    const description = getValueByLocale(partner.descriptions, language);
    const hasLink = !!partner.linkUrl;
    const linkText = getValueByLocale(partner.linkText, language);
    const imageSrc = _get(partner, 'image.original.url');
    const imageAlt = getValueByLocale(partner.imageAlt, language);

    return (
      <Partner key={partner.id}>
        <PartnerImgContainer>
          <PartnerImg alt={imageAlt} src={imageSrc} />
        </PartnerImgContainer>
        <PartnerContent>
          {description && <PartnerDesc>{description}</PartnerDesc>}
          {hasLink && <StyledLink href={partner.linkUrl}>{linkText}</StyledLink>}
        </PartnerContent>
      </Partner>
    );
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container>{partners}</Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

Partners.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      descriptions: pth.descriptions,
      linkText: pth.descriptions,
      linkUrl: PropTypes.string,
      image: pth.image,
      imageAlt: pth.descriptions,
    }),
  ).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(Partners);

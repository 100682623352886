// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  Benefit,
  Title,
  Description,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function SimpleBenefits({ colors, data, i18n: { language }, spacings }) {
  const benefits = data.map((benefit) => {
    const title = getValueByLocale(benefit.title, language);
    const description = getValueByLocale(benefit.descriptions, language);

    return (
      <Benefit key={benefit.id}>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Benefit>
    );
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container>{benefits}</Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

SimpleBenefits.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: pth.descriptions,
      descriptions: pth.descriptions,
    }),
  ).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(SimpleBenefits);

// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))``;

export const Container = styled.div`
  display: grid;

  ${({ theme }) => theme.mediaBelow.minWidth`
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 4rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10rem;
    grid-row-gap: 3.6rem;
  `};
`;

export const Partner = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
`;

export const PartnerImgContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: calc(100% / 3);

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const PartnerImg = styled.img.attrs({
  loading: 'lazy',
})`
  max-height: 8rem;
  max-width: 100%;
`;

export const PartnerContent = styled.div``;

export const PartnerDesc = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.4rem;
    line-height: 1.8rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `};
`;

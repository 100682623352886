// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  StyledButton,
  Title,
  Description,
  StyledLink,
  PhoneNumber,
  PhoneIcon,
  Container,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function ContactUs({ colors, data, i18n: { language }, spacings }) {
  const title = getValueByLocale(data.title, language);
  const description = getValueByLocale(data.descriptions, language);
  const { phone, linkUrl } = data;
  const linkText = getValueByLocale(data.linkText, language);

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
          {linkUrl && (
            <StyledLink href={linkUrl}>
              <StyledButton>{linkText}</StyledButton>
            </StyledLink>
          )}
          {phone && (
            <PhoneNumber>
              <PhoneIcon />
              {phone}
            </PhoneNumber>
          )}
        </Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

ContactUs.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    title: pth.descriptions,
    descriptions: pth.descriptions,
    phone: PropTypes.string,
    linkUrl: PropTypes.string,
    linkText: pth.descriptions,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(ContactUs);

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Socials,
  StyledLink,
  AppleIcon,
  DeezerIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  SpotifyIcon,
  TwitterIcon,
  YoutubeIcon,
} from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function getSocialLinkIcon(key) {
  switch (key) {
    case 'applemusic':
      return <AppleIcon />;
    case 'deezer':
      return <DeezerIcon />;
    case 'facebook':
      return <FacebookIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'linkedin':
      return <LinkedinIcon />;
    case 'spotify':
      return <SpotifyIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'youtube':
      return <YoutubeIcon />;
    default:
      return key;
  }
}

function Social({ colors, data, spacings }) {
  const links = Object.keys(data.social).map(key => ({
    key,
    href: data.social[key],
  }));

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Socials>
          {links.map(item => (
            <StyledLink key={`social-${item.key}`} href={item.href}>
              {getSocialLinkIcon(item.key)}
            </StyledLink>
          ))}
        </Socials>
      </Wrapper>
    </WithCustomTheme>
  );
}

Social.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    social: PropTypes.shape({
      twitter: PropTypes.string,
      linkedin: PropTypes.string,
      facebook: PropTypes.string,
      instagram: PropTypes.string,
      spotify: PropTypes.string,
      youtube: PropTypes.string,
      deezer: PropTypes.string,
      applemusic: PropTypes.string,
    }),
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default Social;

// =============================
// Imports
// =============================

import styled from 'styled-components';

import Link from '../../../../other/link';

import { setSpacings } from '../../../../../helpers/modules';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  height: calc(100vw / 4);
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))``;

export const Container = styled.div``;

/** This image has a 4:1 ratio. */
export const StyledImage = styled.span.attrs(({ alt }) => ({
  'aria-label': alt,
  role: 'img',
}))`
  align-items: center;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: calc(100vw / 4);
  justify-content: center;
  left: 0;
  margin-left: calc(-50vw + 50%);
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 0;
`;

export const Title = styled.div`
  font-size: 5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Breadcrumb = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding-top: 5rem;
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const Crumb = styled.div`
  &:not(:last-child) {
    &:after {
      content: '>';
      margin: 0 0.4rem;
    }
  }
`;

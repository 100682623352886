// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { sendContactEmail } from '../../../../store/actions/ConfigActions';

import ContactForm from '../../../presentationals/modules/contactForm';

// =============================
// Component
// =============================

export default connect(null, {
  sendContactEmail,
})(ContactForm);

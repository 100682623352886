// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { WithCustomTheme } from '@mewo/components';
import { withTranslation } from '../../../../config/i18n';

import Album from './album';
import Artist from './artist';
import Catalog from './catalog';
import Label from './label';
import Playlist from './playlist';
import Publisher from './publisher';
import Track from './track';

import * as pth from '../../../../helpers/proptypes';

// =============================
// Component
// =============================

class SingleEntity extends Component {
  static propTypes = {
    colors: pth.colorsModule.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    doc: PropTypes.object.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    spacings: pth.spacings.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderModule() {
    const { colors, doc, i18n, spacings, t } = this.props;

    switch (doc.type) {
      case 'album':
        return (
          <Album colors={colors} doc={doc} spacings={spacings} locale={i18n.language} t={t} />
        );
      case 'artist':
        return (
          <Artist colors={colors} doc={doc} spacings={spacings} locale={i18n.language} t={t} />
        );
      case 'catalog':
        return (
          <Catalog colors={colors} doc={doc} spacings={spacings} locale={i18n.language} t={t} />
        );
      case 'label':
        return <Label colors={colors} doc={doc} spacings={spacings} locale={i18n.language} t={t} />;
      case 'playlist':
        return (
          <Playlist colors={colors} doc={doc} spacings={spacings} locale={i18n.language} t={t} />
        );
      case 'publisher':
        return (
          <Publisher colors={colors} doc={doc} spacings={spacings} locale={i18n.language} t={t} />
        );
      case 'track':
        return <Track colors={colors} doc={doc} spacings={spacings} locale={i18n.language} t={t} />;
      default:
        return null;
    }
  }

  render() {
    const { colors } = this.props;

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background || 'transparent',
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
              useCustomColor: colors.useCustomColor,
            },
          }
          : {
            colors: {
              useCustomColor: colors.useCustomColor,
            },
          })}
      >
        {this.renderModule()}
      </WithCustomTheme>
    );
  }
}

export default withTranslation(['pages'])(SingleEntity);

// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons } from '@mewo/components';

import AddToPlaylist from '../../other/addToPlaylist';
import Cover from '../../containers/cover';
import Link from '../../other/link';
import { isHoverPrimary } from '../../../helpers/front/getHoverType';

import { DropdownButton } from '../../layouts/entity.styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 1rem;
  width: 100%;

  ${({ noCover }) => (noCover
    ? css`
      grid-template-columns: 4rem minmax(10rem, 20rem) minmax(5rem, 1fr) minmax(8rem, 1fr);
      grid-template-rows: 4rem;
    `
    : css`
      grid-template-columns: 5.5rem minmax(10rem, 20rem) minmax(5rem, 1fr) minmax(8rem, 1fr);
      grid-template-rows: 5.5rem;
    `)};

  ${({ theme }) => theme.mediaAbove.sxga`
    grid-column-gap: 2rem;
  `};
`;

export const TitleSubTitle = styled.div``;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};

  ${({ noCover }) => noCover && css`
    font-size: 1.3rem;
  `};
`;

export const SubTitle = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:first-letter {
    text-transform: capitalize;
  }

  ${({ noCover }) => noCover && css`
    font-size: 1.1rem;
    opacity: 0.5;
  `};
`;

export const Duration = styled.div`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  justify-self: flex-end;
  line-height: 1.9rem;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;

  /** Used to keep a nice spacing between icons. */
  > * {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
  }

  /**
    We only want button icon to hover,
    not icons that might be in a dropdown opened by the button.
  */
  ${({ noHover, theme }) => !noHover
    && (isHoverPrimary(theme.colors)
      ? css`
          > *:hover .opacityHover {
            svg path {
              fill: ${theme.colors.primary};
            }
          }
        `
      : css`
          > *:hover .opacityHover {
            svg path {
              fill-opacity: 0.7;
            }
          }
        `)}
`;

export const AddToPlaylistIcon = styled(Icons.Plus).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  fill: theme.colors.text,
  width: '2rem',
}))``;

export const StyledAddToPlaylist = styled(AddToPlaylist).attrs({
  placement: 'bottom-end',
})``;

export const StyledLink = styled(Link).attrs(({ noHover }) => ({
  inline: true,
  noHover: noHover || false,
}))`
  max-width: 100%;
`;

export const StyledTooltip = styled.span.attrs(({ content }) => ({
  title: content,
}))`
  position: relative;
`;

export const StyledDropdownButton = styled(DropdownButton).attrs({
  placement: 'bottom-end',
})``;

export const ThreeDotIcon = styled(Icons.ThreeDot).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  fill: theme.colors.text,
  noButton: true,
  width: '2rem',
}))``;

export const TrackCover = styled(Cover).attrs({
  classNames: {
    title: 'titleClassName',
    type: 'typeClassName',
  },
  lazyload: false,
  LinkComponent: Link,
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  placeholderType: 'track',
  playButtonSize: 'musicItem',
  type: 'track',
})`
  max-width: 5.5rem;
  width: 100%;
`;

// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

import { setSpacings } from '../../../../../helpers/modules';

import CoverBase from '../../../../containers/cover';
import MusicItem from '../../../../containers/musicitem';

import Button from '../../../button';
import Link from '../../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  margin-top: 2rem;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
`;

export const Cover = styled(CoverBase).attrs({
  classNames: {
    cover: 'coverClassName',
    title: 'titleClassName',
    type: 'typeClassName',
  },
  lazyload: false,
  LinkComponent: Link,
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  playButtonSize: 'musicItem',
})`
  flex-shrink: 0;
  margin: 0 auto;
  position: relative;
  width: 50%;
`;

export const Content = styled.div`
  flex-shrink: 0;
  width: 100%;
`;

export const Type = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  opacity: 0.5;
  width: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 1.6rem;
  width: 100%;
`;

export const Tracks = styled.div``;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))``;

export const PrevBtn = styled(Icons.ArrowDropdownOutline).attrs(() => ({
  dir: 'west',
  width: '3.5rem',
}))`
  cursor: pointer;
  flex-shrink: 0;
`;

export const NextBtn = styled(Icons.ArrowDropdownOutline).attrs(() => ({
  dir: 'east',
  width: '3.5rem',
}))`
  cursor: pointer;
  flex-shrink: 0;
`;

export const StyledMusicItem = styled(MusicItem).attrs(() => ({
  coverStyle: 'controls',
  disableExtraOptions: true,
  withActions: true,
  withDuration: true,
  withVersions: false,
}))``;

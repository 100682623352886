// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { downloadCopyright as downloadCopyrightBase } from '../../../store/actions/EntitiesActions';
import { setTrackLyricsOpen as setTrackLyricsOpenBase } from '../../../store/actions/ModalsActions';
import { getMedia, pause } from '../../../store/actions/PlayerActions';

import MusicItemBase from '../../presentationals/musicitemmini';

import * as playerStates from '../../../store/constants/PlayerStates';
import * as playerCtx from '../../../store/constants/PlayerContexts';

// =============================
// Component
// ============================

class MusicItem extends PureComponent {
  static propTypes = {
    playerStore: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  isCurrent = (trackId, contextName, contextId, contextPosition = null) => {
    const { playerStore } = this.props;

    const playerCurrentId = _get(playerStore, 'current.id', null);
    const playerCurrentContextName = _get(playerStore, 'contextName', null);
    const playerCurrentContextId = _get(playerStore, 'contextId', null);
    const playerCurrentContextPosition = _get(playerStore, 'contextPosition', null);

    return contextName === playerCtx.SEARCH
      ? (playerCurrentId === trackId && playerCurrentContextName === contextName)
      : (
        playerCurrentContextName === contextName
        && playerCurrentContextId === contextId
        && playerCurrentContextPosition === contextPosition
      );
  };

  isPlaying = (trackId, contextName, contextId, contextPosition = null) => {
    const { playerStore } = this.props;

    return !!(
      this.isCurrent(trackId, contextName, contextId, contextPosition)
      && _get(playerStore, 'playerState') === playerStates.PLAYING
    );
  };

  render() {
    return (
      <MusicItemBase
        isCurrent={this.isCurrent}
        isPlaying={this.isPlaying}
        {...this.props}
      />
    );
  }
}

function mapStateToProps({ user, player }) {
  return {
    isLogged: user.isLogged,
    playerStore: player,
  };
}

export default connect(mapStateToProps, {
  play: getMedia,
  pause,
  downloadCopyright: downloadCopyrightBase,
  setTrackLyricsOpen: setTrackLyricsOpenBase,
})(MusicItem);

// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Description = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.8rem;
  max-width: 60rem;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

export const PhoneNumber = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.8rem;
  justify-content: center;
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))``;

export const PhoneIcon = styled(Icons.Phone).attrs(({ theme }) => ({
  fill: theme.colors.primary,
  width: '2rem',
}))`
  margin-right: 0.8rem;
`;

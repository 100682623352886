// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  Client,
  ClientImgContainer,
  ClientImg,
  StyledLink,
} from './styles';
import { WideBackground } from '../singleEntity/styles';
import { withTranslation } from '../../../../config/i18n';
import { getValueByLocale } from '../../../../helpers/i18n';

// =============================
// Component
// =============================

function Clients({ colors, data, i18n: { language }, spacings }) {
  const { items } = data;

  const hasMoreThanFiveClient = items.length >= 5;

  const clients = items.map((client) => {
    const image = _get(client, 'image.original.url');
    const imageAlt = getValueByLocale(client.imageAlt, language);
    const hasLink = !!client.linkUrl;

    if (hasLink) {
      return (
        <StyledLink key={client.id} href={client.linkUrl}>
          <Client>
            <ClientImgContainer>
              <ClientImg alt={imageAlt} src={image} />
            </ClientImgContainer>
          </Client>
        </StyledLink>
      );
    }

    return (
      <Client key={client.id}>
        <ClientImgContainer>
          <ClientImg alt={imageAlt} src={image} />
        </ClientImgContainer>
      </Client>
    );
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container hasMoreThanFiveClient={hasMoreThanFiveClient}>
          {clients}
        </Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

Clients.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        linkUrl: PropTypes.string,
        image: pth.image,
        imageAlt: pth.descriptions,
      }),
    ),
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation([])(Clients);

// =============================
// Imports
// =============================

import styled from 'styled-components';
import { alpha } from '@material-ui/core';

import { setSpacings } from '../../../../helpers/modules';

import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

const gridGap = '2rem';

export const Grid = styled.div`
  display: grid;
  grid-gap: ${gridGap};

  ${({ theme }) => theme.mediaBelow.minWidth`
    ${theme.helpers.hideScrollbar};
    grid-auto-columns: 90%;
    grid-auto-flow: column;
    margin-left: calc(-50vw + 50%);
    overflow-x: auto;
    padding: 0 ${paddingX};
    scroll-snap-type: x mandatory;
    width: 100vw;

    /** Fix last element spacings */
    &::after {
      content: '';
      display: block;
      height: 0.1rem;
      margin-left: -${gridGap};
      width: ${paddingX};
    }
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `};
`;

export const Item = styled.span.attrs(({ alt }) => ({
  'aria-label': alt,
  role: 'img',
}))`
  cursor: pointer;
  /* Image has a 2:1 ratio. */
  padding-top: 50%;
  position: relative;
  scroll-snap-align: center;
  width: 100%;

  &:before {
    background-color: ${({ theme }) => alpha(theme.colors.text, 0.2)};
    background-image: url(${({ src }) => src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 10rem 0 ${({ theme }) => theme.colors.boxShadow};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: box-shadow ${({ theme }) => theme.transition.fast};
    width: 100%;
  }

  &:hover:before {
    box-shadow: inset 0 0 10rem 0 ${({ theme }) => theme.colors.boxShadowHover};
  }
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    padding: 0.8rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding: 2rem;
  `};
`;

export const TitleSubTitle = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.6rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.8rem;
  `};
`;

export const SubTitle = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.2rem;
`;

export const CompanyNameType = styled.div`
  align-items: flex-start;
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  /** We only want 2 line maximum so we use (font-size * 2). */
  height: 2.8rem;
  overflow: hidden;
  width: 100%;
`;

export const CompanyName = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  max-width: 50%;
`;

export const Type = styled.div`
  font-size: 1.4rem;
  max-width: 40%;
`;

// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../config/i18n';

import { getValueByLocale } from '../../../../../helpers/i18n';
import * as pth from '../../../../../helpers/proptypes';

import {
  Wrapper,
  Container,
  ImageContainer,
  Image,
  Content,
  Title,
  Description,
  Header,
  Controls,
  PrevBtn,
  NextBtn,
  StyledLink,
  StyledButton,
  ButtonAnimationWrapper,
} from './styles';
import { WideBackground } from '../../singleEntity/styles';

// =============================
// Component
// =============================

class Slider extends Component {
  static displayName = 'SliderDesktop';

  static propTypes = {
    colors: pth.colorsModule.isRequired,
    data: PropTypes.shape({
      // If true, will use brand color on top of default background color.
      enableBrandedHeader: PropTypes.bool,
      // Array of slide data.
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: pth.descriptions,
          descriptions: pth.descriptions,
          linkText: pth.descriptions,
          linkUrl: PropTypes.string,
          image: pth.image,
          imageAlt: pth.descriptions,
        }),
      ),
    }).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    spacings: pth.spacings.isRequired,
  };

  state = {
    current: 0,
    lastClick: 0,
    direction: 'forward',
  };

  componentDidMount() {
    this.interval = setInterval(this.handleAutoScroll, 5000);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  getBackgroundImage = () => {
    const { data } = this.props;
    const { current } = this.state;

    return _get(data[current], 'thumbnail.original.url', '');
  };

  getCurrentSlideData = () => {
    const { data: { items }, i18n: { language } } = this.props;
    const { current } = this.state;

    return {
      id: items[current].id,
      title: getValueByLocale(items[current].title, language),
      description: getValueByLocale(items[current].descriptions, language),
      linkText: getValueByLocale(items[current].linkText, language),
      linkUrl: items[current].linkUrl,
      imageSrc: _get(items[current], 'image.original.url'),
      imageAlt: getValueByLocale(items[current].imageAlt, language),
    };
  };

  handleAutoScroll = () => {
    const { data: { items } } = this.props;
    const { lastClick } = this.state;

    // We autoscroll if user didn't click on a prev/next btn for 5seconds.
    if ((+new Date() - lastClick) / 1000 > 5) {
      return this.setState(
        () => ({
          direction: 'forward',
        }),
        this.setState(({ current }) => ({
          current: current + 1 >= items.length ? 0 : current + 1,
          lastClick: +new Date(),
        })),
      );
    }

    return false;
  };

  handleClickOnNextBtn = () => {
    const { data: { items } } = this.props;

    return this.setState(
      () => ({
        direction: 'forward',
      }),
      this.setState(({ current }) => ({
        current: current + 1 >= items.length ? 0 : current + 1,
        lastClick: +new Date(),
      })),
    );
  };

  handleClickOnPrevBtn = () => {
    const { data: { items } } = this.props;

    return this.setState(
      {
        direction: 'backward',
      },
      this.setState(({ current }) => ({
        current: current - 1 < 0 ? items.length - 1 : current - 1,
        lastClick: +new Date(),
      })),
    );
  };

  render() {
    const { colors, data: { enableBrandedHeader, items }, spacings } = this.props;
    const { current, direction } = this.state;

    const currentSlide = this.getCurrentSlideData();

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background || 'transparent',
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
              useCustomColor: colors.useCustomColor,
            },
          }
          : {
            colors: {
              useCustomColor: colors.useCustomColor,
            },
          })}
      >
        <Wrapper spacings={spacings}>
          <WideBackground />
          <Container
            animate="animate"
            enableBrandedHeader={enableBrandedHeader}
            key={current}
            spacings={spacings}
          >
            <ImageContainer>
              <Image
                alt={currentSlide.imageAlt}
                animationDirection={direction}
                src={currentSlide.imageSrc}
              />
            </ImageContainer>
            <Content>
              <Header>
                <Title animationDirection={direction} enableBrandedHeader={enableBrandedHeader}>
                  {currentSlide.title}
                </Title>

                {items.length >= 2 && (
                  <Controls>
                    <PrevBtn
                      enableBrandedHeader={enableBrandedHeader}
                      onClick={this.handleClickOnPrevBtn}
                    />
                    <NextBtn
                      enableBrandedHeader={enableBrandedHeader}
                      onClick={this.handleClickOnNextBtn}
                    />
                  </Controls>
                )}
              </Header>

              {currentSlide.description && (
                <Description animationDirection={direction}>{currentSlide.description}</Description>
              )}

              {currentSlide.linkUrl && (
                <StyledLink href={currentSlide.linkUrl}>
                  <ButtonAnimationWrapper animationDirection={direction}>
                    <StyledButton>{currentSlide.linkText}</StyledButton>
                  </ButtonAnimationWrapper>
                </StyledLink>
              )}
            </Content>
          </Container>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default withTranslation(['pages'])(Slider);

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import Link from '../../../../other/link';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getTitle, getCoverUrl, getBaseRoute, getUrl } from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';

import { Wrapper, Container, Cover, Informations, Type, Name, Description, DiscoverButton } from './styles';
import { WideBackground } from '../styles';

// =============================
// Component
// =============================

const ArtistEntity = ({ doc, locale, spacings, t }) => {
  const description = getValueByLocale(doc.descriptions, locale);

  return (
    <Wrapper spacings={spacings}>
      <WideBackground />
      <Container>
        <Cover
          contextId={doc.id}
          contextName={playerCtx.ARTIST}
          linkProps={{
            route: getBaseRoute('artists'),
            nextAs: getUrl('artists')(doc),
          }}
          src={getCoverUrl('artists')(doc)}
          alt={getTitle('artists')(doc)}
        />
        <Informations>
          <Type>{t('pages:custom_page.artist')}</Type>
          <Name>{getTitle('artists')(doc)}</Name>
          {!!description && <Description>{description}</Description>}
          <Link route={getBaseRoute('artists')} nextAs={getUrl('artists')(doc)}>
            <DiscoverButton>{t('pages:custom_page.discover_artist')}</DiscoverButton>
          </Link>
        </Informations>
      </Container>
    </Wrapper>
  );
};

ArtistEntity.propTypes = {
  doc: PropTypes.shape({
    aliases: PropTypes.arrayOf(PropTypes.string),
    descriptions: pth.descriptions,
    fullName: PropTypes.string,
    id: PropTypes.string,
    image: pth.image,
    tenant: pth.user,
    type: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default ArtistEntity;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import dayjs from 'dayjs';

import { withTranslation } from '../../../../config/i18n';

import { getUrl, getBaseRoute } from '../../../../helpers/entity';
import { getPlaylistTracksCoversUrl } from '../../../../helpers/entity/playlist';
import * as playerCtx from '../../../../store/constants/PlayerContexts';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  StyledLink,
  Grid,
  Playlist,
  TitleDate,
  Title,
  UpdatedAt,
  PlaylistCover,
} from './styles';
import { WideBackground } from '../singleEntity/styles';
import { NoData, NoDataBtn, NoDataMsg, NoDataLink } from '../styles';

// =============================
// Component
// =============================

function MasonryUserPlaylists({ colors, data, spacings, t }) {
  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        {!data.length && (
          <NoData>
            <NoDataMsg>
              {t('pages:custom_page.modules.masonry_user_playlists.you_have_no_playlist')}
            </NoDataMsg>
            <NoDataLink route="/search">
              <NoDataBtn>{t('pages:custom_page.modules.masonry_user_playlists.go_to')}</NoDataBtn>
            </NoDataLink>
          </NoData>
        )}
        <Grid>
          {data.map((playlist, i) => (
            <StyledLink
              route={getBaseRoute('userplaylists')}
              nextAs={getUrl('userplaylists')(playlist)}
              // eslint-disable-next-line react/no-array-index-key
              key={`${playlist.id}-${i}`}
            >
              <Playlist>
                <PlaylistCover
                  contextId={playlist.id}
                  contextName={playerCtx.USER_PLAYLIST}
                  src={getPlaylistTracksCoversUrl(playlist, 'small', 'xsmall')}
                  alt={playlist.name}
                />
                <TitleDate>
                  <Title>{playlist.name}</Title>
                  <UpdatedAt>{dayjs().to(dayjs(playlist.updatedAt))}</UpdatedAt>
                </TitleDate>
              </Playlist>
            </StyledLink>
          ))}
        </Grid>
      </Wrapper>
    </WithCustomTheme>
  );
}

MasonryUserPlaylists.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ).isRequired,
  spacings: pth.spacings.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['pages'])(MasonryUserPlaylists);

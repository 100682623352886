// =============================
// Imports
// =============================

import styled from 'styled-components';
import { alpha } from '@material-ui/core';
import { motion } from 'framer-motion';
import { Icons } from '@mewo/components';

import { setSpacings } from '../../../../../helpers/modules';

import Link from '../../../../other/link';

import { paddingX } from '../../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Container = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
`;

export const BackgroundImageContainer = styled.div`
  margin-left: calc(-50vw + 50%);
  padding-top: calc(100vh * 0.8);
  position: relative;
  width: 100vw;
`;

export const BackgroundImage = styled(motion.span).attrs(({ theme, alt }) => ({
  role: 'img',
  'aria-label': alt,
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants: {
    enter: {
      opacity: 1,
      transition: {
        duration: theme.transition.numeric.fast,
        ease: theme.transitionTiming.pose.linear,
      },
    },
    exit: {
      opacity: 0.4,
      transition: {
        duration: theme.transition.numeric.fast,
        ease: theme.transitionTiming.pose.linear,
      },
    },
  },
}))`
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:before {
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  left: 0;
  flex-direction: column;
  padding: ${paddingX} 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const CompanyNameType = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 0.8rem;
  `};
`;

export const CompanyName = styled.div`
  font-size: 1.6rem;
  line-height: 2rem;
`;

export const Type = styled.div`
  font-size: 1.6rem;
  line-height: 2rem;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 2.4rem;
    line-height: 1.3;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 3.2rem;
    line-height: 5rem;
  `};
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  margin-top: auto;
`;

export const PlayBtn = styled(Icons.PlayOutline).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '4rem',
}))`
  margin-right: 1.6rem;
`;

export const ControlsInfo = styled.div``;

export const ControlsTitle = styled.div`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 2rem;

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
`;

export const ControlsSubTitle = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.8rem;
  opacity: 0.5;
`;

export const ThumbnailWrapper = styled.div`
  border-top: 0.1rem solid ${({ theme }) => alpha(theme.colors.text, 0.3)};
`;

export const PaddingZone = styled.div`
  ${({ theme }) => theme.helpers.hideScrollbar};
  display: flex;
  margin-left: calc(-50vw + 50%);
  overflow-x: auto;
  padding: ${paddingX} ${paddingX} 0;
  width: 100vw;

  /** Fix last element spacing. */
  &::after {
    content: '';
    display: block;
    flex-shrink: 0;
    height: 0.1rem;
    width: ${paddingX};
  }
`;

export const Thumbnail = styled.div`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.9;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const ThumbnailInfo = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    justify-content: flex-start;
  `};
`;

export const ThumbnailTitle = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  height: 100%;
  left: 0;
  line-height: 1.2;
  margin-bottom: 0.4rem;
  padding: 0.8rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const ThumbnailImageContainer = styled.div`
  flex-shrink: 0;
  margin-bottom: 0;
  position: relative;
  width: 13rem;
`;

export const ThumbnailImage = styled.span.attrs(({ alt }) => ({
  role: 'img',
  'aria-label': alt,
}))`
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  display: block;
  /** Image has a 3:2 ratio. */
  padding-top: 66.66%;
  width: 100%;
`;

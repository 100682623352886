// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import MusicItemMini from '../../../containers/musicitemmini';
import { Desktop, Mobile } from '../../../other/responsive';

import * as playerCtx from '../../../../store/constants/PlayerContexts';

import * as pth from '../../../../helpers/proptypes';

import { WideBackground } from '../singleEntity/styles';
import { NoData, NoDataBtn, NoDataLink, NoDataMsg } from '../styles';
import { Wrapper, Grid, StyledMusicItem } from './styles';

// =============================
// Component
// =============================

function TracksFavorites({ colors, data, spacings, t }) {
  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        {!data.length && (
          <NoData>
            <NoDataMsg>
              {t('pages:custom_page.modules.tracks_favorites.you_have_no_favorite')}
            </NoDataMsg>
            <NoDataLink route="/search">
              <NoDataBtn>{t('pages:custom_page.modules.tracks_favorites.go_to')}</NoDataBtn>
            </NoDataLink>
          </NoData>
        )}
        <Grid>
          <Desktop>
            {data.map((track, i) => (
              <MusicItemMini
                // eslint-disable-next-line react/no-array-index-key
                key={`${track.id}-${i}`}
                track={track}
                contextName={playerCtx.SEARCH}
              />
            ))}
          </Desktop>
          <Mobile>
            {data.map((track, i) => (
              <StyledMusicItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${track.id}-${i}`}
                contextName={playerCtx.SEARCH}
                music={track}
              />
            ))}
          </Mobile>
        </Grid>
      </Wrapper>
    </WithCustomTheme>
  );
}

TracksFavorites.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(pth.smalltrack).isRequired,
  spacings: pth.spacings.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['pages'])(TracksFavorites);

// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import * as playerCtx from '../../../../store/constants/PlayerContexts';

import { getValueByLocale } from '../../../../helpers/i18n';
import {
  getTitle,
  getCoverUrl,
  getUrl,
  getSearchUrl,
  getBaseRoute,
} from '../../../../helpers/entity';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  AlbumCover,
  ArtistCover,
  CatalogCover,
  LabelCover,
  PlaylistCover,
  PublisherCover,
  TrackCover,
  Container,
} from './styles';
import { WideBackground } from '../singleEntity/styles';
import { getTrackAlbum } from '../../../../helpers/entity/track';

// =============================
// Component
// =============================

class DoubleEntity extends Component {
  static propTypes = {
    colors: pth.colorsModule.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    docs: PropTypes.arrayOf(PropTypes.object).isRequired,
    spacings: pth.spacings.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  renderEntity = (doc = false) => {
    const {
      i18n: { language },
      t,
    } = this.props;

    const nextDescriptions = _get(doc, 'descriptions', []).length
      ? doc.descriptions
      : _get(doc, 'data.descriptions', []);

    switch (doc.type) {
      case 'album':
        return (
          <AlbumCover
            contextId={doc.data.id}
            contextName={playerCtx.ALBUM}
            linkProps={{
              route: getBaseRoute('albums'),
              nextAs: getUrl('albums')(doc.data),
            }}
            description={getValueByLocale(nextDescriptions, language)}
            key={`${doc.data.id}`}
            preText={t('pages:custom_page.album')}
            src={getCoverUrl('albums')(doc.data)}
            title={getTitle('albums')(doc.data)}
            alt={getTitle('albums')(doc.data)}
          />
        );

      case 'artist':
        return (
          <ArtistCover
            contextId={doc.data.id}
            contextName={playerCtx.ARTIST}
            linkProps={{
              route: getBaseRoute('artists'),
              nextAs: getUrl('artists')(doc.data),
            }}
            description={getValueByLocale(nextDescriptions, language)}
            key={`${doc.data.id}`}
            preText={t('pages:custom_page.artist')}
            src={getCoverUrl('artists')(doc.data)}
            title={getTitle('artists')(doc.data)}
            alt={getTitle('artists')(doc.data)}
          />
        );

      case 'catalog':
        return (
          <CatalogCover
            contextId={doc.data.id}
            contextName={playerCtx.CATALOG}
            linkProps={{
              route: getBaseRoute('catalogs'),
              nextAs: getUrl('catalogs')(doc.data),
            }}
            description={getValueByLocale(nextDescriptions, language)}
            key={`${doc.data.id}`}
            preText={t('pages:custom_page.catalog')}
            src={getCoverUrl('catalogs')(doc.data)}
            title={getTitle('catalogs')(doc.data)}
            alt={getTitle('catalogs')(doc.data)}
          />
        );

      case 'label':
        return (
          <LabelCover
            contextId={doc.data.id}
            linkProps={{
              route: getSearchUrl('labels')(doc.data),
            }}
            description={getValueByLocale(nextDescriptions, language)}
            key={`${doc.data.id}`}
            preText={t('pages:custom_page.label')}
            src={getCoverUrl('labels')(doc.data)}
            title={getTitle('labels')(doc.data)}
            alt={getTitle('labels')(doc.data)}
          />
        );

      case 'playlist':
        return (
          <PlaylistCover
            contextId={doc.data.id}
            contextName={playerCtx.PLAYLIST}
            linkProps={{
              route: getBaseRoute('playlists'),
              nextAs: getUrl('playlists')(doc.data),
            }}
            description={getValueByLocale(nextDescriptions, language)}
            key={`${doc.data.id}`}
            preText={t('pages:custom_page.playlist')}
            src={getCoverUrl('playlists')(doc.data)}
            title={getTitle('playlists')(doc.data)}
            alt={getTitle('playlists')(doc.data)}
          />
        );

      case 'publisher':
        return (
          <PublisherCover
            contextId={doc.data.id}
            linkProps={{
              route: getSearchUrl('publishers')(doc.data),
            }}
            description={getValueByLocale(nextDescriptions, language)}
            key={`${doc.data.id}`}
            preText={t('pages:custom_page.publisher')}
            src={getCoverUrl('publishers')(doc.data)}
            title={getTitle('publishers')(doc.data)}
            alt={getTitle('publishers')(doc.data)}
          />
        );

      case 'track':
        return (
          <TrackCover
            contextName={playerCtx.SEARCH}
            linkProps={
              getUrl('tracks')(doc.data)
                ? {
                  route: getBaseRoute('tracks'),
                  nextAs: getUrl('tracks')(doc.data),
                }
                : {}
            }
            description={getValueByLocale(nextDescriptions, language)}
            key={`${doc.data.id}`}
            preText={t('pages:custom_page.track')}
            src={getCoverUrl('tracks')(doc.data)}
            title={getTitle('tracks')(doc.data)}
            alt={getTrackAlbum(doc.data).title}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { colors, docs, spacings } = this.props;

    return (
      <WithCustomTheme
        customTheme={() => (colors.useCustomColor
          ? {
            colors: {
              background: colors.background || 'transparent',
              text: colors.text,
              primary: colors.primary,
              primaryText: colors.primaryText,
              useCustomColor: colors.useCustomColor,
            },
          }
          : {
            colors: {
              useCustomColor: colors.useCustomColor,
            },
          })}
      >
        <Wrapper spacings={spacings}>
          <WideBackground />
          <Container>
            {this.renderEntity(docs[0])}
            {this.renderEntity(docs[1])}
          </Container>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default withTranslation(['pages'])(DoubleEntity);

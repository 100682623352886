// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import { WideBackground } from '../singleEntity/styles';
import {
  Wrapper,
  Container,
  Benefit,
  BenefitImgContainer,
  BenefitImg,
  BenefitDesc,
  BenefitTitle,
  StyledLink,
} from './styles';

// =============================
// Component
// =============================

function BenefitsAlt({ colors, data, i18n: { language }, spacings }) {
  if (!data.length) return null;

  const benefits = data.map((benefit) => {
    const imageSrc = _get(benefit.image, 'original.url', undefined);
    const imageAlt = getValueByLocale(benefit.imageAlt, language);
    const title = getValueByLocale(benefit.title, language);
    const description = getValueByLocale(benefit.descriptions, language);
    const hasLink = !!benefit.linkUrl;
    const linkText = getValueByLocale(benefit.linkText, language);

    return (
      <Benefit key={benefit.id}>
        <BenefitImgContainer>
          <BenefitImg src={imageSrc} alt={imageAlt} />
        </BenefitImgContainer>
        {title && <BenefitTitle>{title}</BenefitTitle>}
        {description && <BenefitDesc>{description}</BenefitDesc>}
        {hasLink && <StyledLink href={benefit.linkUrl}>{linkText}</StyledLink>}
      </Benefit>
    );
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container>{benefits}</Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

BenefitsAlt.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: pth.descriptions,
      descriptions: pth.descriptions,
      linkText: pth.descriptions,
      linkUrl: PropTypes.string,
      image: pth.image,
      imageAlt: pth.descriptions,
    }),
  ).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(BenefitsAlt);

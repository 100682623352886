// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';

import { setSpacings } from '../../../../helpers/modules';

import CoverBase from '../../../containers/cover';
import Link from '../../../other/link';

import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))``;

const gridGap = '2rem';

export const Grid = styled.div`
  ${({ theme }) => theme.helpers.hideScrollbar};
  display: grid;
  grid-gap: ${gridGap};

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-left: calc(-50vw + 50%);
    overflow-x: auto;
    padding: 0 ${paddingX};
    width: 100vw;

    /** Fix last element spacings */
    &::after {
      content: '';
      display: block;
      height: 0.1rem;
      margin-left: -${gridGap};
      width: ${paddingX};
    }
  `};

  ${({ theme, useRectangleVariant }) => (useRectangleVariant
    ? css`
      ${theme.mediaBelow.minWidth`
        grid-auto-columns: 60%;
        grid-auto-flow: column;
      `};

      ${theme.mediaAbove.minWidth`
        grid-template-columns: repeat(3, minmax(0, 1fr));
      `};
    `
    : css`
      ${theme.mediaBelow.minWidth`
        grid-auto-columns: 40%;
        grid-auto-flow: column;
      `};

      ${theme.mediaAbove.minWidth`
        grid-template-columns: repeat(5, minmax(0, 1fr));
      `};
    `)};
`;

export const Cover = styled(CoverBase).attrs({
  classNames: {
    cover: 'coverClassName',
    title: 'titleClassName',
    type: 'typeClassName',
  },
  lazyload: false,
  LinkComponent: Link,
})`
  & .coverClassName {
    margin-bottom: 1.2rem;
  }

  & .titleClassName {
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  & .typeClassName {
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fontWeight.normal};

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
`;

export const RectangularItem = styled.span.attrs(({ alt }) => ({
  'aria-label': alt,
  role: 'img',
}))`
  overflow: hidden;
  display: block;
  /** Image has a 2:1 ratio. */
  padding-top: 50%;
  position: relative;
  width: 100%;

  &:before {
    background-color: ${({ theme }) => alpha(theme.colors.text, 0.2)};
    background-image: url(${({ src }) => src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 10rem 0 ${({ theme }) => theme.colors.boxShadow};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: box-shadow ${({ theme }) => theme.transition.fast};
    width: 100%;
  }

  &:hover:before {
    box-shadow: inset 0 0 10rem 0 ${({ theme }) => theme.colors.boxShadowHover};
  }
`;

export const RectangularTitle = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.6rem;
    padding: 0.4rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.8rem;
    padding: 1.2rem;
  `};
`;

// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';

import { setSpacings } from '../../../../helpers/modules';

import Button from '../../button';
import Link from '../../../other/link';

import { titleSearchBarHeight } from './variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))``;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'medium',
}))``;

export const BackgroundImage = styled.span.attrs(({ alt }) => ({
  'aria-label': alt,
  role: 'img',
}))`
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  left: 0;
  margin-left: calc(-50vw + 50%);
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
`;

export const BackgroundVideoWrapper = styled.div`
  height: 100vh;
  left: calc(-50vw + 50%);
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
`;

const defaultX = '16';
const defaultY = '9';

export const VideoRatioKeeper = styled.div`
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  /** It seems that switching height with width make it works on mobile. */
  ${({ ratioX, ratioY, theme }) => (!ratioX || !ratioY
    ? css`
      ${theme.mediaBelow.minWidth`
        width: calc(100vh * (${defaultX} / ${defaultY}));
        height: calc(100vw * (${defaultX} / ${defaultY}));
      `};

      ${theme.mediaAbove.minWidth`
        height: calc(100vh * (${defaultX} / ${defaultY}));
        width: calc(100vw * (${defaultX} / ${defaultY}));
      `};
    `
    : css`
      ${theme.mediaBelow.minWidth`
        width: calc(100vh * (${ratioX} / ${ratioY}));
        height: calc(100vw * (${ratioX} / ${ratioY}));
      `};

      ${theme.mediaAbove.minWidth`
        height: calc(100vh * (${ratioX} / ${ratioY}));
        width: calc(100vw * (${ratioX} / ${ratioY}));
      `};
    `)};
`;

export const BackgroundVideo = styled(ReactPlayer).attrs(() => ({
  playing: true,
  /*
    Set to true or false to display native player controls.
    For Vimeo videos, hiding controls must be enabled by the video owner.
  */
  controls: false,
  height: '100%',
  loop: true,
  muted: true,
  width: '100%',
  config: {},
}))`
  left: 0;
  position: absolute;
  top: 0;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
  transition: margin-top ${({ theme }) => theme.transition.medium};

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 3rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    ${theme.helpers.textEllipsis};
    font-size: 4.3rem;
    line-height: 6rem;
    margin-bottom: 3rem;
  `};
`;

export const TitleSearchBarWrapper = styled.div`
  /* This need to be above scPresearch so it does not get behind the modal Overlay. */
  z-index: ${({ theme }) => theme.zIndex.scPresearch + 1};

  ${({ moveUp, theme }) => theme.mediaAbove.minWidth`
    height: ${titleSearchBarHeight};
    left: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    transition: 300ms;
    top: 50%;

    ${moveUp && css`
      top: 20%;
    `};
  `};
`;

export const VideoLinkWrapper = styled.div`
  bottom: 0;
  left: 0;
  margin-left: calc(-50vw + 50%);
  padding: 3rem;
  position: absolute;
  width: 100vw;
`;

export const VideoLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.8rem;
`;

export const ScrollIndicatorWrapper = styled.div`
  bottom: 2rem;
  left: 50%;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
`;

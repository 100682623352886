// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { WithCustomTheme } from '@mewo/components';
import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import { Content } from '../../../layouts/common.text.styles';
import { Wrapper } from './styles';
import { WideBackground } from '../singleEntity/styles';

// =============================
// Component
// =============================

function Text({ colors, doc, i18n: { language }, spacings }) {
  const text = getValueByLocale(doc, language);

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Content dangerouslySetInnerHTML={{ __html: text }} />
      </Wrapper>
    </WithCustomTheme>
  );
}

Text.propTypes = {
  colors: pth.colorsModule.isRequired,
  doc: pth.descriptions.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(Text);

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../config/i18n';

import { getValueByLocale } from '../../../../../helpers/i18n';
import * as pth from '../../../../../helpers/proptypes';

import { WideBackground } from '../../singleEntity/styles';
import { Wrapper, StyledImage, Title, Breadcrumb, Crumb, StyledLink } from './styles';

// =============================
// Component
// =============================

function Banner({ colors, data, i18n: { language }, spacings }) {
  const title = getValueByLocale(data.title, language);
  const imageSrc = _get(data, 'image.original.url');
  const imageAlt = getValueByLocale(data.imageAlt, language);
  const { breadcrumbs } = data;

  // Breadcrumbs
  const crumbs = breadcrumbs.map((item) => {
    const crumbTitle = getValueByLocale(item.title, language);
    const crumbHasLink = !!item.linkUrl;

    if (crumbHasLink) {
      return (
        <Crumb key={item.id}>
          <StyledLink href={item.linkUrl}>{crumbTitle}</StyledLink>
        </Crumb>
      );
    }

    return <Crumb key={item.id}>{crumbTitle}</Crumb>;
  });

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        {crumbs && <Breadcrumb>{crumbs}</Breadcrumb>}
        <StyledImage src={imageSrc} alt={imageAlt}>
          <Title as={crumbs.length >= 1 ? 'h1' : 'div'}>{title}</Title>
        </StyledImage>
      </Wrapper>
    </WithCustomTheme>
  );
}

Banner.displayName = 'BannerDesktop';

Banner.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    image: pth.image,
    imageMobile: pth.image,
    imageAlt: pth.descriptions,
    title: pth.descriptions,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: pth.descriptions,
        linkUrl: PropTypes.string,
      }),
    ),
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(Banner);

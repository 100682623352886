// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../../helpers/modules';

import { CoverBase } from '../styles';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
`;

export const Cover = styled(CoverBase).attrs({
  classNames: {
    title: 'titleClassName',
    type: 'typeClassName',
  },
  type: 'album',
  placeholderType: 'album',
  vinyl: true,
})`
  width: 40%;

  &:first-child:not(:last-child) {
    margin-top: 10rem;
  }

  &:first-child:last-child {
    margin: 0 auto;
  }

  ${({ theme }) => theme.mediaAbove.minWidth`
    width: 25rem;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    width: 32rem;
  `};


  & .titleClassName {
    ${({ theme }) => theme.mediaBelow.minWidth`
      font-size: 1.8rem;
      line-height: 2rem;
    `};

    ${({ theme }) => theme.mediaAbove.minWidth`
      font-size: 2rem;
      line-height: 2.4rem;
    `};
  }

  & .typeClassName {
    ${({ theme }) => theme.mediaBelow.minWidth`
      font-size: 1.4rem;
    `};

    ${({ theme }) => theme.mediaAbove.minWidth`
      font-size: 1.6rem;
    `};

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
`;

// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import { WideBackground } from '../singleEntity/styles';
import { Wrapper, StyledButton, StyledLink, Container } from './styles';

// =============================
// Component
// =============================

function Button({ colors, data, i18n: { language }, spacings }) {
  const title = getValueByLocale(data.title, language);

  return (
    <WithCustomTheme
      customTheme={() => (colors.useCustomColor
        ? {
          colors: {
            background: colors.background || 'transparent',
            text: colors.text,
            primary: colors.primary,
            primaryText: colors.primaryText,
            useCustomColor: colors.useCustomColor,
          },
        }
        : {
          colors: {
            useCustomColor: colors.useCustomColor,
          },
        })}
    >
      <Wrapper spacings={spacings}>
        <WideBackground />
        <Container placement={data.placement}>
          <StyledLink href={data.linkUrl}>
            <StyledButton>{title}</StyledButton>
          </StyledLink>
        </Container>
      </Wrapper>
    </WithCustomTheme>
  );
}

Button.propTypes = {
  colors: pth.colorsModule.isRequired,
  data: PropTypes.shape({
    title: pth.descriptions.isRequired,
    linkUrl: PropTypes.string,
    placement: PropTypes.oneOf(['left', 'center', 'right']),
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  spacings: pth.spacings.isRequired,
};

export default withTranslation(['pages'])(Button);

// =============================
// Imports
// =============================

import styled from 'styled-components';

import { setSpacings } from '../../../../../helpers/modules';

import Link from '../../../../other/link';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  ${({ spacings }) => spacings && setSpacings(spacings)};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  width: 100%;
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const LinkContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const BenefitImgContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 4rem;
  justify-content: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`;

export const BenefitImg = styled.img.attrs({
  loading: 'lazy',
})`
  max-height: 100%;
  max-width: 100%;
`;

export const BenefitTitle = styled.div`
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 2.8rem;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`;

export const BenefitDesc = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const Placeholder = styled.div``;

export const LineWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

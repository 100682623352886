// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from 'next/router';

import * as LastRoute from '../../../helpers/lastroute';
import { hasAccessToPrivate } from '../../../helpers/user';

// =============================
// Component
// =============================

class PrivateRoute extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** Location protocol, like https. */
    locationProtocol: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { hasAccess, locationProtocol } = this.props;

    LastRoute.capture(locationProtocol);

    if (!hasAccess) {
      Router.push('/login');
    }
  }

  componentDidUpdate(prevProps) {
    const { hasAccess } = this.props;

    if (prevProps.hasAccess && !hasAccess) {
      Router.push('/login');
    }
  }

  render() {
    const { children, hasAccess } = this.props;

    if (!hasAccess) return null;
    return children;
  }
}

function mapStateToProps({ core, user, config }) {
  return {
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
    /** Location protocol, like https. */
    locationProtocol: core.serverContext.locationProtocol,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
